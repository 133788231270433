// @noflow
import {
  DynamicDefaultTrackEventProperties,
  dynamicDefaultTrackEventPropertiesVar
} from '@/services/apollo'

type DefaultProperties = {
  source: string
  screen_identifier: string
} & DynamicDefaultTrackEventProperties

type TrackEventProperties = {
  component_identifier: string
  screen_identifier?: string
  sub_event_name?: string
}

type ComponentAnalyticsEnabled = {
  identifier: string
  screenIdentifier?: string | null
  subEventName?: string | null
  trackDisabledPresses?: boolean
  disableAnalytics?: never
}

type ComponentAnalyticsDisabled = {
  disableAnalytics: boolean
  identifier?: never
  screenIdentifier?: never
  subEventName?: never
  trackDisabledPresses?: never
}

type AnalyticsProps = ComponentAnalyticsEnabled | ComponentAnalyticsDisabled

/**
 * Track an analytics event
 *
 * This method should only be called from page loads or generic
 * components (atoms and molecules).
 * Direct interaction with this function from a screen is anti-pattern.
 *
 * @example
   ```
    import { trackEvent, AnalyticsProps } from 'services/segment'

    type Props = ButtonProps & AnalyticsProps

    trackEvent('Component Clicked', {
      component_identifier: identifier
    })
   ```
 *
 * @param {string} eventName - The name of the event to track
 * @param {TrackEventProperties} properties - The properties to attach to the event
 */
const trackEvent = (
  eventName: string,
  properties: TrackEventProperties
): void => {
  /**
   * Get the current route name in order to set the `screen_identifier`
   * property dynamically.
   *
   * If the route is not available, we set the route name to `unknown`.
   *
   * Alternatively, we can pass optional `screen_identifier` prop to the
   * `trackEvent` function to override the default value.
   *
   * TODO: Establish if we want to use pathname or react route name
   */
  const routeName = window.location.pathname ?? 'unknown'

  /**
   * Get the dynamic default properties from the Apollo reactive variable.
   * When one of the properties changes, the reactive variable will be
   * updated and future `trackEvent` function calls will be called with new
   * dynamic properties.
   */
  const dynamicDefaultTrackEventProperties =
    dynamicDefaultTrackEventPropertiesVar()

  const defaultProperties: DefaultProperties = {
    source: 'web',
    screen_identifier: routeName,
    ...dynamicDefaultTrackEventProperties
  }

  window.analytics.track(eventName, {
    ...defaultProperties,
    ...properties
  })
}

export type {
  AnalyticsProps,
  ComponentAnalyticsEnabled,
  ComponentAnalyticsDisabled
}

export { trackEvent }
