// @flow

import type { Gender } from '../shared_types/rails_models/dogs'
import type { Language } from '@/packs/localisation'

type PossessivePronoun =
  | 'their'
  | 'her'
  | 'his'
  | 'hun'
  | 'haar'
  | 'zijn'
  | 'jego'
  | 'jej'
  | 'ich'
  | 'leur'
  | 'son'
  | 'sa'
  | 'ihre'
  | 'seine'

type SubjectPronoun =
  | 'he'
  | 'she'
  | 'they'

type ObjectPronoun =
  | 'him'
  | 'her'
  | 'them'

type PronounContext =
  | 'female'
  | 'male'
  | 'plural'

type ToLocalisedSentenceParams = {|
    arr: Array<string>,
    lng?: string,
    oxfordComma?: boolean
  |}

// Due to GraphQL Naming Rules, we're unable to use kebab-case to distinguish
// languages with regional variation, i.e. nl vs nl-BE. However, i18next also
// expects the use of kebab-case in order to make use of its built in features,
// such as pluralisation which is heavily used across the codebase. As a
// compromise, we store Flemish as nl_BE in the backend to satisfy GraphQL and
// utilise nl-BE with i18next (see initialisation of i18n in localisation.ts).
// This case handling here will ensure that any functions that may receive either
// forms will be able to manage the logic by then returning `nl_BE`
// Further reading:
// https://graphql-rules.com/rules/naming
// https://www.i18next.com/how-to/faq#how-should-the-language-codes-be-formatted

const lngStringToLngType = (lng: string): Language => {
  switch (lng) {
    case 'en': {
      return 'en'
    }
    case 'nl': {
      return 'nl'
    }
    case 'nl-BE':
    case 'nl_BE': {
      return 'nl_BE'
    }
    case 'pl': {
      return 'pl'
    }
    case 'pl-PL':
    case 'pl_PL': {
      return 'pl_PL'
    }
    case 'fr' : {
      return 'fr'
    }
    case 'de-DE':
    case 'de_DE': {
      return 'de_DE'
    }
    default: {
      throw new Error(`Cannot run lngStringToLngType for ${lng}`)
    }
  }
}

const pronounContext = (genders: Array<Gender>, lng: string): PronounContext => {
  if (genders.length === 0) { throw new Error('Invalid array to pronounContext - empty') }
  // Because different language grammars treat groups of mixed gender differently,
  // we want to provide this context. For example, in English and Dutch, singular
  // pronouns are gender sensitive (he/she) and plural pronouns are not (they).
  // In languages like Spanish and French however, there are two forms of the plural
  // pronoun they (ellos/ellas) so we would need gender context in the plural form.
  const language = lngStringToLngType(lng)
  switch (language) {
    case 'en':
    case 'nl':
    case 'nl_BE':
    case 'pl':
    case 'pl_PL':
    case 'fr':
    case 'de_DE':
      if (genders.length > 1) {
        return 'plural'
      }
      return genders[0]
    default: {
      throw new Error(`language ${lng} is not supported.`)
    }
  }
}

const toLocalisedSentence = (params: ToLocalisedSentenceParams): string => {
  const { arr, lng } = params
  const oxfordComma = params.oxfordComma === undefined ? false : params.oxfordComma
  const language = lng ? lngStringToLngType(lng) : 'en'
  if (arr.length === 0) { throw new Error('Invalid array to toSentence - empty') }
  if (arr.length === 1) {
    return arr[0]
  } else if (arr.length === 2) {
    switch (language) {
      case 'en':
        return arr.join(' and ')
      case 'nl':
      case 'nl_BE':
        return arr.join(' en ')
      case 'pl':
      case 'pl_PL':
        return arr.join(' i ')
      case 'fr':
        return arr.join(' et ')
      case 'de_DE':
        return arr.join(' und ')
      default: {
        throw new Error(`language ${language} is not supported.`)
      }
    }
  } else {
    const lastName = arr[arr.length - 1]
    const allButLastName = arr.slice(0, -1)
    switch (language) {
      case 'en':
        // eslint-disable-next-line i18next/no-literal-string
        return `${allButLastName.join(', ')}${oxfordComma ? ',' : ''} and ${lastName}`
      case 'nl':
      case 'nl_BE':
        // eslint-disable-next-line i18next/no-literal-string
        return `${allButLastName.join(', ')}${oxfordComma ? ',' : ''} en ${lastName}`
      case 'pl':
      case 'pl_PL':
        // eslint-disable-next-line i18next/no-literal-string
        return `${allButLastName.join(', ')}${oxfordComma ? ',' : ''} i ${lastName}`
      case 'fr':
        // eslint-disable-next-line i18next/no-literal-string
        return `${allButLastName.join(', ')}${oxfordComma ? ',' : ''} et ${lastName}`
      case 'de_DE':
        // eslint-disable-next-line i18next/no-literal-string
        return `${allButLastName.join(', ')}${oxfordComma ? ',' : ''} und ${lastName}`
      default: {
        throw new Error(`language ${language} is not supported.`)
      }
    }
  }
}

const possessive = (s: string, lng: Language = 'en'): string => {
  // NOTE: as we expand to other languages beyond Dutch and English, we will have to adjust this
  // function to return just the name of the possessor so that we can construct other forms such as:
  // 'the box of the dog' for languages that do not use apostrophes to indicate possession
  const language = lngStringToLngType(lng)
  if (s.length === 0) { throw new Error('Invalid string to possessive - empty') }
  switch (language) {
    case 'en': {
      // eslint-disable-next-line i18next/no-literal-string
      const suffix = s.endsWith('s') ? `'` : `'s`
      return `${s}${suffix}`
    }
    case 'nl':
    case 'nl_BE':
    case 'pl':
    case 'pl_PL':
      for (const i of 'aeiou') {
        // Note that this is not an exact rule since there are exceptions in Dutch. If a name
        // ends with a mute schwa (e), there would be no apostrophe. Identifying the vowel sound
        // would be almost impossible so we fall back to this more general rule.
        if (s.endsWith(i)) {
          // eslint-disable-next-line i18next/no-literal-string
          return `${s}'s`
        }
      }
      if (s.endsWith('s')) {
        return `${s}'`
      }
      // eslint-disable-next-line i18next/no-literal-string
      return `${s}s`
    case 'fr':
      return `${s}`
    case 'de_DE':
      // Only use an apostrophe then s when the name ends in s, ss, ß, tz, z, x
      if (/(s|ss|ß|tz|z|x)$/i.test(s)) {
        // eslint-disable-next-line i18next/no-literal-string
        return `${s}'s`
      }
      // In all other cases, just use s
      // eslint-disable-next-line i18next/no-literal-string
      return `${s}s`
    default:
      throw new Error(`language ${lng} is not supported.`)
  }
}

const possessivePronoun = (genders: Array<Gender>, language: Language = 'en'): PossessivePronoun => {
  /**
  * DEPRECATED
  *
  * This helper function should not be used. Provide pronoun context
  * (see pronounContext) into the localisation yml file insted
  */
  if (genders.length === 0) { throw new Error('Invalid array to possessivePronoun - empty') }
  if (genders.length > 1) {
    switch (language) {
      case 'nl':
      case 'nl_BE': {
        return 'hun'
      }
      case 'pl':
      case 'pl_PL': {
        return 'ich'
      }
      case 'fr': {
        return 'leur'
      }
      case 'de_DE': {
        return 'ihre'
      }
      default: {
        return 'their'
      }
    }
  }
  switch (genders[0]) {
    case 'male': {
      switch (language) {
        case 'nl':
        case 'nl_BE': {
          return 'zijn'
        }
        case 'pl':
        case 'pl_PL': {
          return 'jego'
        }
        case 'fr': {
          return 'son'
        }
        case 'de_DE': {
          return 'seine'
        }
        default: {
          return 'his'
        }
      }
    }
    case 'female': {
      switch (language) {
        case 'nl':
        case 'nl_BE': {
          return 'haar'
        }
        case 'pl':
        case 'pl_PL': {
          return 'jej'
        }
        case 'fr': {
          return 'sa'
        }
        case 'de_DE': {
          return 'ihre'
        }
        default: {
          return 'her'
        }
      }
    }
    default: {
      (genders[0]: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`possessivePronoun not implemented for ${genders[0]}`)
    }
  }
}

const capitaliseFirstLetter = (word: string): string => {
  if (word.length === 0) { throw new Error('Invalid string to capitaliseFirstLetter - empty') }
  return `${word[0].toUpperCase()}${word.slice(1)}`
}

const subjectPronoun = (genders: Array<Gender>): SubjectPronoun => {
  /**
  * DEPRECATED
  *
  * This helper function should not be used. Provide pronoun context
  * (see pronounContext) into the localisation yml file insted
  */
  if (genders.length === 0) { throw new Error('Invalid array to subjectPronoun - empty') }
  if (genders.length > 1) { return 'they' }
  switch (genders[0]) {
    case 'male': {
      return 'he'
    }
    case 'female': {
      return 'she'
    }
    default: {
      (genders[0]: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`subjectPronoun not implemented for ${genders[0]}`)
    }
  }
}

const objectPronoun = (genders: Array<Gender>): ObjectPronoun => {
  if (genders.length === 0) { throw new Error('Invalid array to objectPronoun - empty') }
  if (genders.length > 1) { return 'them' }
  switch (genders[0]) {
    case 'male': {
      return 'him'
    }
    case 'female': {
      return 'her'
    }
    default: {
      (genders[0]: empty) // eslint-disable-line no-unused-expressions
      throw new Error(`objectPronoun not implemented for ${genders[0]}`)
    }
  }
}



export {
  toLocalisedSentence,
  possessive,
  possessivePronoun,
  capitaliseFirstLetter,
  subjectPronoun,
  objectPronoun,
  pronounContext,
  lngStringToLngType
}

export type {
  PossessivePronoun,
  SubjectPronoun,
  ObjectPronoun,
  PronounContext,
  ToLocalisedSentenceParams
}
