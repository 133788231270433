// @noflow
import type { BreedSelectorQuery_breeds as Breed } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'

type PopularBreeds = {
  [key: Breed['key']]: {
    number: number
    slug: string
  }
}

const popularBreeds: PopularBreeds = {
  basset_hound: {
    number: 350,
    slug: 'breed_card_basset_hound'
  },
  beagle: {
    number: 2202,
    slug: 'breed_card_beagle'
  },
  border_collie: {
    number: 4652,
    slug: 'breed_card_border_collie'
  },
  boxer: {
    number: 1403,
    slug: 'breed_card_boxer'
  },
  bulldog: {
    number: 1290,
    slug: 'breed_card_bulldog'
  },
  cavalier_king_charles_spaniel: {
    number: 3499,
    slug: 'breed_card_cavalier_king_charles_spaniel'
  },
  cavapoo_cavalier_king_charles_spaniel_poodle_mix: {
    number: 9855,
    slug: 'breed_card_cavapoo'
  },
  chihuahua: {
    number: 6196,
    slug: 'breed_card_chihuahua'
  },
  cockapoo_cocker_spaniel_poodle_mix: {
    number: 24256,
    slug: 'breed_card_cockapoo'
  },
  cocker_spaniel: {
    number: 11283,
    slug: 'breed_card_cocker_spaniel'
  },
  dachshund: {
    number: 4753,
    slug: 'breed_card_dachshund'
  },
  dobermann: {
    number: 267,
    slug: 'breed_card_dobermann'
  },
  french_bulldog: {
    number: 11418,
    slug: 'breed_card_frenchie'
  },
  german_shepherd: {
    number: 1910,
    slug: 'breed_card_german_shepherd'
  },
  golden_retriever: {
    number: 5297,
    slug: 'breed_card_golden_retriever'
  },
  goldendoodle_golden_retriever_poodle_mix: {
    number: 1793,
    slug: 'breed_card_goldendoodle'
  },
  jack_russell_terrier: {
    number: 7449,
    slug: 'breed_card_jack_russell'
  },
  labradoodle_labrador_retriever_poodle_mix: {
    number: 3838,
    slug: 'breed_card_labradoodle'
  },
  labrador_retriever: {
    number: 10537,
    slug: 'breed_card_labrador'
  },
  maltese: {
    number: 4124,
    slug: 'breed_card_maltese'
  },
  maltipoo_maltese_poodle_mix: {
    number: 2600,
    slug: 'breed_card_maltipoo'
  },
  mini_american_shepherd: {
    number: 59,
    slug: 'breed_card_mini_american_shepherd'
  },
  miniature_dachshund: {
    number: 7285,
    slug: 'breed_card_miniature_dachshund'
  },
  pembroke_welsh_corgi: {
    number: 497,
    slug: 'breed_card_corgi'
  },
  pomeranian: {
    number: 2946,
    slug: 'breed_card_pomeranian'
  },
  pug: { number: 3138, slug: 'breed_card_pug' },
  shih_tzu: {
    number: 6523,
    slug: 'breed_card_shih_tzu'
  },
  sprocker: {
    number: 1918,
    slug: 'breed_card_sprocker'
  },
  staffordshire_bull_terrier: {
    number: 4188,
    slug: 'breed_card_staffordshire_bull_terrier'
  },
  tibetan_terrier: {
    number: 895,
    slug: 'breed_card_tibetan_terrier'
  },
  vizsla: {
    number: 441,
    slug: 'breed_card_vizsla'
  },
  west_highland_white_terrier: {
    number: 2366,
    slug: 'breed_card_west_highland_white_terrier'
  },
  whippet: {
    number: 2789,
    slug: 'breed_card_whippet'
  }
}

export default popularBreeds
