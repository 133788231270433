import { gql } from '@apollo/client'

const USER_TYPE_QUERY = gql`
  query UserQuery {
    currentUser {
      __typename
      ... on User {
        preferredLanguage
      }
      ... on Guest {
        preferredLanguage
      }
    }
  }
`

export default USER_TYPE_QUERY
