// @noflow
import type { Language } from '@/packs/localisation'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DiscountBasis } from '@/utils/butternutbox/discountCodes'
import { UserType } from '@/utils/wizardCtaLink'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Cta from './components/CTA'
import DogDetailsForm from './components/DogDetailsForm'
import Plan from './components/Plan'
import withApollo from '@/components/apollo/withApollo'
import Image from '@/components/shared/elements/Image/Image'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  preferredLanguage: Language
  currentUserType?: UserType
  screenIdentifier: string
  discountValue: number
  discountBasis: DiscountBasis
  shippingCountryCode: CountryCode
}

type DogDetails = {
  age: number
  weight: string | number
  interactedWithAge: boolean
}

const namespace = 'homepage'
const copyContext = 'rough_cost_calculator'
const baseClass = 'rough-cost-calculator__cost-calculator'

const FullSectionRoughCostCalculator = ({
  preferredLanguage,
  currentUserType,
  screenIdentifier,
  discountValue,
  discountBasis,
  shippingCountryCode
}: Props): JSX.Element | null => {
  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)
  const isDesktop = windowWidth > BREAKPOINTS.lg

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__text-wrapper`}>
        <h3 className={`${!isDesktop ? 'display-36' : 'display-45'} no-scale`}>
          {t(`${copyContext}.title`)}
        </h3>
        <div className={`${baseClass}__card`}>
          <p className={`text-regular-20 no-scale ${baseClass}__text`}>
            {t(`${copyContext}.description`)}
          </p>
          <DogDetailsForm />
          <div className={`${baseClass}__plan-row`}>
            <Plan
              description={t(`${copyContext}.mix_plan`)}
              planType="mix"
              copyContext={copyContext}
              namespace={namespace}
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
              discountValue={discountValue}
              discountBasis={discountBasis}
            />
            <Plan
              description={t(`${copyContext}.full_butternut`)}
              planType="all"
              copyContext={copyContext}
              namespace={namespace}
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
              discountValue={discountValue}
              discountBasis={discountBasis}
            />
          </div>
          <Cta
            namespace={namespace}
            text={`${copyContext}.build_your_box`}
            currentUserType={currentUserType}
            screenIdentifier={screenIdentifier}
          />
        </div>
      </div>
      <div className={`${baseClass}__image-wrapper`}>
        <Image
          alt={t(`${copyContext}.image_alt`)}
          slug="charlie-standing-in-front-of-open-fridge"
          image={{
            width: 640,
            height: 857,
            resizeMode: 'resize_to_fit'
          }}
          className={`${baseClass}__image-wrapper__image`}
        />
      </div>
    </div>
  )
}

export { Props }
export type { DogDetails }
export default withApollo(FullSectionRoughCostCalculator)
