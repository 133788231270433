import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { offsetLimitPagination } from '@apollo/client/utilities'

const csrfMeta = document.querySelector('meta[name=csrf-token]')
const csrfToken = csrfMeta?.getAttribute('content')

const batchLink = new BatchHttpLink({
  batchMax: 10,
  batchInterval: 20, // Wait no more than 20ms after first batched operation
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': csrfToken
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 200,
    jitter: false
  },
  attempts: {
    max: 2,
    retryIf: (error) => !!error
  }
})

/**
 * Throw GraphQL errors so they are easier to detect (in development only)
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        // eslint-disable-next-line i18next/no-literal-string
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${JSON.stringify(path)}`
      )
    })
  // eslint-disable-next-line i18next/no-literal-string
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

let apolloLinks: ApolloLink[] = [retryLink, batchLink]

if (process.env.NODE_ENV === 'development') {
  apolloLinks = [errorLink].concat(apolloLinks)
}

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          ordersByDate: offsetLimitPagination(['to', 'order'])
        }
      },
      Meal: {
        // Disable normalization for `Meal` type by not defining a unique identifier
        // for these entities. This will prevent Apollo Client from storing them
        // separately in the global cache.
        keyFields: false
      }
    }
  }),
  link: ApolloLink.from(apolloLinks)
})

export default client
