// @flow

import type { Language } from '@/packs/localisation'

type Ordinality =
  | 'st'
  | 'nd'
  | 'rd'
  | 'th'
  | 'e'
  | ''
  | '.'

/**
  * This method returns the ordinality of the number passed in given the
  * following guidelines:
  *
  *   1: If the last 2 digits of the number are between 10 and 20 (inclusive),
  *      then return 'th' (111th, 2419th, etc)
  *   2: If the last digit of the number is a 1, return 'st' (1st, 1251st, etc)
  *   3: If the last digit of the number is a 2, return 'nd' (2nd, 1252nd, etc)
  *   4: If the last digit of the number is a 3, return 'rd' (3rd, 1253rd, etc)
  *   5: In all other cases, return a 'th'
  */
const ordinality = (n: number, lng: Language = 'en'): Ordinality => {
  // TODO: Consult how to structure Dutch ordinal numbers
  // if the last 2 digits of n are between 10 and 20 (inclusive)
  switch (lng) {
    case 'en': {
      if (n % 100 >= 10 && n % 100 <= 20) return 'th'
      // if n ends in a 1
      if (n % 10 === 1) return 'st'
      // if n ends in a 2
      if (n % 10 === 2) return 'nd'
      // if n ends in a 3
      if (n % 10 === 3) return 'rd'
      // all other cases
      return 'th'
    }
    case 'nl':
    case 'nl_BE': {
      return 'e'
    }
    case 'pl':
    case 'pl_PL': {
      // For Polish, there is no suffix, so we can return an empty string
      return '.'
    }
    case 'fr': {
      return 'e'
    }
    case 'de_DE': {
      return '.'
    }
    default: {
      throw new Error(`language ${lng} is not supported.`)
    }
  }
}

export { ordinality }

export type { Ordinality }
