type WizardCtaLink = {
  url: string
  text: string
}

enum UserType {
  PreWizardGuest = 'PreWizardGuest',
  Guest = 'Guest',
  User = 'User'
}

const wizardCtaLink = (currentUserType: UserType | string): WizardCtaLink => {
  switch (currentUserType) {
    case UserType.PreWizardGuest: {
      return {
        url: '/wizard/new',
        text: 'cta_build'
      }
    }
    case UserType.Guest: {
      return {
        url: '/plans/recipes',
        text: 'cta_plan'
      }
    }
    case UserType.User: {
      return {
        url: '/dashboard',
        text: 'cta_account'
      }
    }
    default: {
      return {
        url: '/wizard/new',
        text: 'cta_build'
      }
    }
  }
}

export { wizardCtaLink, WizardCtaLink, UserType }
