// @noflow
import { TOptions } from 'i18next'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Arrow from 'assets/images/icons/arrows/arrow--white.svg'

type ExpandableIconButtonProps = {
  namespace?: string
  text: string | Array<string>
  variables?: TOptions
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabledOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  translate?: boolean
  toggleIconButton: boolean
  onFocus?: () => void
}

const ExpandableIconButton = ({
  namespace,
  text,
  variables,
  disabled,
  onClick,
  disabledOnClick,
  translate = true,
  toggleIconButton = false,
  onFocus
}: ExpandableIconButtonProps): JSX.Element => {
  const { t } = useTranslation(namespace)

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (disabled) {
        if (disabledOnClick) disabledOnClick(e)
      } else {
        onClick && onClick(e)
      }
    },
    [disabled, disabledOnClick, onClick]
  )

  return (
    <button
      className={`expandable-icon-button__button ${
        toggleIconButton
          ? 'expandable-icon-button__button__short'
          : 'expandable-icon-button__button__long'
      }`}
      disabled={disabled}
      onClick={handleOnClick}
      type="button"
      aria-label={translate ? t(text) : (text as string)}
      onFocus={onFocus}
    >
      <div className="button-icon">{<img src={Arrow} alt="" />}</div>
      <div className="button-text">
        <span className="text-mono-18">{t(text, variables)}</span>
      </div>
    </button>
  )
}

export default ExpandableIconButton
