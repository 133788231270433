// @noflow
import { differenceInSeconds } from 'date-fns'
import * as Cookies from 'js-cookie'
import { isEqual } from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'

import CookieConsent, {
  cookieConsentBannerState,
  cookieConsentModalState
} from '@/components/shared/CookieConsent/CookieConsent'
import { Variant } from '@/components/shared/CookieConsent/Modals/CookieConsentModal'

import { Code as CountryCode } from '@/types'

import * as scroll from './scroll'

enum ConsentModeValue {
  granted = 'granted',
  denied = 'denied'
}

type CookiePreferences = {
  marketing: boolean
  analytics: boolean
}

type BannerShown = {
  state: 'banner-shown'
}

type Accepted = {
  state: 'accepted'
  preferences: CookiePreferences
}

type CookieConsent = null | BannerShown | Accepted

type ConsentMode = {
  functionality_storage: ConsentModeValue
  security_storage: ConsentModeValue
  ad_storage: ConsentModeValue
  analytics_storage: ConsentModeValue
  personalization_storage: ConsentModeValue
  ad_user_data: ConsentModeValue
  ad_personalization: ConsentModeValue
}

declare global {
  interface Window {
    initSentry: () => void
    initSegment: (preferences: CookiePreferences) => void
    initFBPixel: () => void
    gtag: (consent: string, action: string, consentMode: ConsentMode) => void
    dataLayer: Record<string, unknown>[]
    initCookieConsent: () => void
    triggerCookieConsentModal: () => void
  }
}

const thirdPartyCookies = {
  analyticsOnly: [
    '_ga',
    '_gid',
    '_hjid',
    '_hjIncludedInSample',
    /^amplitude_id_[0-9a-f]+$/,
    /amplitude_idundefined*/,
    'ga_client_id'
  ],
  marketingOnly: [
    '_fbp',
    '_gat',
    '_twitter_hash',
    '_uetsid',
    'fr',
    'MUID',
    'MUIDB',
    'personalization_id'
  ],
  both: ['ajs_anonymous_id', 'ajs_group_id', 'ajs_user_id']
}

const thirdPartyLocalStorageItems = {
  analyticsOnly: [
    '_hjid',
    /^amplitude_unsent_[0-9a-f]+$/,
    'amplitude_unsent_identity',
    /^amplitude_unsent_identity_[0-9a-f]+$/
  ],
  marketingOnly: ['_uetsid', '_uetsid_exp', 'taboola global:user-id'],
  both: [
    'ajs_anonymous_id',
    'ajs_group_id',
    'ajs_group_properties',
    'ajs_user_id',
    'ajs_user_traits',
    'debug'
  ]
}

const deleteCookies = (pattern: RegExp | string): void => {
  if (typeof pattern === 'string') {
    Cookies.remove(pattern)
  } else {
    Object.keys(Cookies.get())
      .filter((name: string): boolean => pattern.test(name))
      .forEach((name: string): void => {
        Cookies.remove(name)
        Cookies.remove(name, { path: '/', domain: window.location.host })
        Cookies.remove(name, { path: '/', domain: `.${window.location.host}` })
      })
  }
}

const deleteLocalStorageItems = (pattern: RegExp | string): void => {
  if (typeof pattern === 'string') {
    window.localStorage.removeItem(pattern)
  } else {
    Object.keys(window.localStorage)
      .filter((name: string): boolean => pattern.test(name))
      .forEach((name: string): void => window.localStorage.removeItem(name))
  }
}

const initMarketingCookies = () => {
  if (window.initFBPixel) {
    window.initFBPixel()
  }
}

const removeMarketingCookies = () => {
  thirdPartyCookies.marketingOnly.forEach(deleteCookies)
  thirdPartyLocalStorageItems.marketingOnly.forEach(deleteLocalStorageItems)
}

const initAnalyticsCookies = ({ analytics, marketing }: CookiePreferences) => {
  if (window.initSentry) {
    window.initSentry()
  }

  if (window.initSegment) {
    window.initSegment({ analytics, marketing })
  }
}

const removeAnalyticsCookies = () => {
  thirdPartyCookies.analyticsOnly.forEach(deleteCookies)
  thirdPartyLocalStorageItems.analyticsOnly.forEach(deleteLocalStorageItems)
}

const removeBothCookies = () => {
  thirdPartyCookies.both.forEach(deleteCookies)
  thirdPartyLocalStorageItems.both.forEach(deleteLocalStorageItems)
}

const updateConsentMode = ({ analytics, marketing }: CookiePreferences) => {
  const consentMode = {
    functionality_storage: analytics
      ? ConsentModeValue.granted
      : ConsentModeValue.denied,
    security_storage: analytics
      ? ConsentModeValue.granted
      : ConsentModeValue.denied,
    ad_storage: marketing ? ConsentModeValue.granted : ConsentModeValue.denied,
    analytics_storage: analytics
      ? ConsentModeValue.granted
      : ConsentModeValue.denied,
    personalization_storage: marketing
      ? ConsentModeValue.granted
      : ConsentModeValue.denied,
    ad_user_data: marketing
      ? ConsentModeValue.granted
      : ConsentModeValue.denied,
    ad_personalization: marketing
      ? ConsentModeValue.granted
      : ConsentModeValue.denied
  }

  window.gtag('consent', 'update', consentMode)
  localStorage.setItem('consentMode', JSON.stringify(consentMode))
  // This event is sent so that GTM can trigger tags after a user has consented
  // It is set on a delay such that we make sure consent is properly set in GTM
  setTimeout((): void => {
    window.dataLayer.push({ event: 'consentUpdated' })
  }, 100)
}

const cookieConsentBannerElement = document.getElementById('cookies-banner')

const cookieConsentLocalStorageItem = () =>
  window.localStorage.getItem('cookieConsent')

const initCookieConsent = (): void => {
  if (!cookieConsentBannerElement) return

  const shippingCountryCode = Cookies.get('user_country_code')

  const variant =
    shippingCountryCode === CountryCode.DE ? Variant.Strict : Variant.Default

  const cookieConsentLocalStorateItem = cookieConsentLocalStorageItem()
  const consent: CookieConsent | null = cookieConsentLocalStorateItem
    ? JSON.parse(cookieConsentLocalStorateItem)
    : null

  const consentAccepted = consent?.state === 'accepted'

  if (!consent) {
    window.analytics.track('Loaded New Cookie Variant')

    window.localStorage.setItem(
      'cookieConsent',
      JSON.stringify({
        state: 'banner-shown'
      })
    )
  }

  const applyPreferences = (preferences: CookiePreferences): void => {
    const previousConsentLocalStorageItem = cookieConsentLocalStorageItem()
    const previousConsent: CookieConsent | null =
      previousConsentLocalStorageItem
        ? JSON.parse(previousConsentLocalStorageItem)
        : null

    if (!preferences.analytics && !preferences.marketing) {
      window.analytics = {
        /* eslint-disable @typescript-eslint/no-empty-function */
        track: (): void => {},
        identify: (): void => {},
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ready: (): void => {},
        page: (): void => {}
        /* eslint-enable @typescript-eslint/no-empty-function */
      }
      removeBothCookies()
    }

    // If you haven't already got a previous level of consent - set one according
    // to your preferences
    if (!previousConsent || previousConsent.state === 'banner-shown') {
      // If a new user scrolls past the threshold, Accepted preferences are automatically applied.
      // This guard prevents the preferences from being applied twice if they interact with the
      // banner and the previousConsent object in localStorage hasn't been set yet.
      if (window.localStorage.getItem('consentMode') != null) return

      return (
        initAnalyticsCookies(preferences),
        initMarketingCookies(),
        updateConsentMode(preferences)
      )
    }

    // If you do have a previous level of consent set in localStorage
    if (previousConsent) {
      // If your analytics preferences have changed from negative to positive
      if (!previousConsent.preferences.analytics && preferences.analytics) {
        initAnalyticsCookies(preferences)
      } else if (
        // If your analytics preferences have changed from positive to negative
        previousConsent.preferences.analytics &&
        !preferences.analytics
      ) {
        removeAnalyticsCookies()
      }

      // If your marketing preferences have changed from negative to positive
      if (!previousConsent.preferences.marketing && preferences.marketing) {
        initMarketingCookies()
      } else if (
        // If your marketing preferences have changed from positive to negative
        previousConsent.preferences.marketing &&
        !preferences.marketing
      ) {
        removeMarketingCookies()
      }

      // If your previous preferences are different from your current preferences
      if (!isEqual(previousConsent.preferences, preferences)) {
        updateConsentMode(preferences)
      }
    }
  }

  if (Variant.Default && !consent) {
    const startTime = new Date()
    scroll.checkPixelIntersection((): void => {
      const endTime = new Date()
      if (differenceInSeconds(endTime, startTime) < 5) {
        window.analytics.track('Cookie Scroll Threshold Met', {
          properties: {
            secondsUntilScrollThreshold: differenceInSeconds(endTime, startTime)
          }
        })
      }
      applyPreferences({ marketing: true, analytics: true })
    })
  }

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-no-bind
    <CookieConsent variant={variant} applyPreferences={applyPreferences} />,
    cookieConsentBannerElement
  )

  if (!consentAccepted) {
    switch (variant) {
      case Variant.Strict:
        cookieConsentModalState({ visible: true })
        break
      default:
        cookieConsentBannerState({ visible: true })
    }
  }
}

const triggerCookieConsentModal = (): void => {
  cookieConsentModalState({ visible: true })
}

window.initCookieConsent = initCookieConsent
window.triggerCookieConsentModal = triggerCookieConsentModal

export { initCookieConsent, triggerCookieConsentModal }

export type { CookieConsent, CookiePreferences, Accepted }
