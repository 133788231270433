import React from 'react'
import { Markup } from 'react-render-markup'

type InjectedMarkupTextProps = {
  text: string
}

type InjectedTextProps = {
  children: Array<string>
  type: string
}

const allowedHtmlElement = ['strong', 'em', 'b', 'a', 'br', 'span', 'accent'] // strips all other elements

// This allows us to add specific styling to certain word(s) in a string by
// wrapping custom <accent> wrappers around them and declaring the style 'type'
// with a class within the Text component stylesheet. See Storybook for examples
// under 'With Underline'
const AccentText = ({ children, type }: InjectedTextProps) => {
  return <span className={type}>{children[0]}</span>
}

const replace = {
  accent: AccentText
}

const InjectedMarkupText = ({
  text
}: InjectedMarkupTextProps): React.ReactElement => {
  return (
    <Markup
      allowedElements={allowedHtmlElement}
      replace={replace}
      markup={text}
    />
  )
}

export default InjectedMarkupText
