// @noflow
import Cookies from 'js-cookie'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import popularBreeds from '@/constants/PopularBreeds'

import BreedAutocomplete from './components/BreedAutocomplete/BreedAutocomplete'
import BreedCard, {
  NonPopularBreed,
  PopularBreed
} from './components/BreedCard/BreedCard'
import BreedQuestionnaire from './components/BreedQuestionaire'
import segmentTrack from '@/components/analytics/Analytics'

import type { HomeBreedSelectorQuery_breeds as Breed } from './queries/__generated__/HomeBreedSelectorQuery'

enum BreedSelectorVariant {
  Homepage = 'homepage',
  Generic = 'generic'
}

type Props = {
  ctaDestination: string
  isPreWizardGuest: boolean
  scrollToOnFocus?:
    | {
        topOffset: number
      }
    | false
  variant: BreedSelectorVariant
  screenIdentifier: string
}

const BreedSelector = ({
  scrollToOnFocus,
  isPreWizardGuest,
  ctaDestination,
  variant = BreedSelectorVariant.Generic,
  screenIdentifier
}: Props): JSX.Element => {
  const namespace = 'paid_traffic'
  const { t } = useTranslation(namespace)
  const [selectedBreed, setSelectedBreed] = useState<
    PopularBreed | NonPopularBreed | null
  >(null)

  const breedSelectorRef = useRef<HTMLDivElement>(null)

  // Callbacks
  const onOpenCallback = useCallback(() => setSelectedBreed(null), [])
  const linkCTADestination = useCallback(() => {
    const eventName = 'Component Clicked'
    const properties = {
      screen_identifier: screenIdentifier,
      component_identifier: 'breed_selector_cta',
      breed: selectedBreed?.key
    }

    segmentTrack(eventName, properties)

    window.location.href = ctaDestination
  }, [ctaDestination, screenIdentifier, selectedBreed?.key])

  const onFocusCallback = useCallback(() => {
    if (scrollToOnFocus && breedSelectorRef.current) {
      const { topOffset } = scrollToOnFocus
      const containerDistanceFromTop =
        breedSelectorRef.current.getBoundingClientRect().top

      const y = containerDistanceFromTop + window.pageYOffset - topOffset

      // On iOS, when a user focuses on an input, the window is automatically
      // scrolled so the input element is placed above the keyboard. By setting a
      // very small timeout, we can ensure that our custom scroll position does not
      // scroll too far which was observed previously.
      setTimeout(() => window.scrollTo({ top: y, behavior: 'smooth' }), 100)
    }
  }, [scrollToOnFocus])

  const onBreedSelection = useCallback((breed: Breed) => {
    const matchingPopularBreed = popularBreeds[breed.key]

    // Set Breed ID cookie to be accessed by the Wizard to allow
    // for pre-populated breed selection
    Cookies.set(
      'breedSelectorBreedId',
      JSON.stringify({
        id: parseInt(breed.id),
        key: breed.key,
        name: breed.name
      })
    )

    if (matchingPopularBreed) {
      return setSelectedBreed({
        ...breed,
        ...matchingPopularBreed,
        isPopular: true
      })
    } else {
      return setSelectedBreed({
        ...breed,
        isPopular: false
      })
    }
  }, [])

  switch (variant) {
    case BreedSelectorVariant.Homepage: {
      return (
        <div id="breed-questionaire" ref={breedSelectorRef}>
          <BreedQuestionnaire
            namespace={namespace}
            onClickCTA={linkCTADestination}
            variant={variant}
            isPreWizardGuest={isPreWizardGuest}
            selectedBreed={selectedBreed}
            events={{
              onBreedSelection: onBreedSelection,
              onListOpen: onOpenCallback,
              onFocus: onFocusCallback,
              onInputEmpty: () => null
            }}
          />
        </div>
      )
    }
    default: {
      return (
        <div id="breed-questionaire" ref={breedSelectorRef}>
          <BreedAutocomplete
            loadingPlaceholder={t(
              `breed_hero_section.field_placeholder_loading`
            )}
            placeholder={t('breed_hero_section.placeholder_v2')}
            embeddedCTA
            onClickCTA={linkCTADestination}
            events={{
              onBreedSelection,
              onListOpen: onOpenCallback,
              onFocus: onFocusCallback,
              onInputEmpty: () => null
            }}
            variant={variant}
          />
          {selectedBreed !== null && (
            <div>
              <div className="breed-selector__breed-card-section">
                <div className="breed-selector__breed-card-wrapper">
                  <BreedCard
                    selectedBreed={selectedBreed}
                    isPreWizardGuest={isPreWizardGuest}
                    namespace={namespace}
                    variant={variant}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )
    }
  }
}

export default BreedSelector
export { BreedSelectorVariant }
