// @noflow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import InjectedMarkupText from '../../InjectedMarkupText'
import type { ClientReferralLinkProps } from '../RafModal'

type Props = {
  namespace: string
  closeRafModal: () => void
  rafMessage: ClientReferralLinkProps['rafMessage']
  referrerName: ClientReferralLinkProps['referrerName']
  referrerDogNames: ClientReferralLinkProps['referrerDogNames']
  referrerDogNumber: ClientReferralLinkProps['referrerDogNumber']
}

const ClientReferralLinkContent = ({
  namespace,
  closeRafModal,
  rafMessage,
  referrerName,
  referrerDogNames,
  referrerDogNumber
}: Props): React.ReactElement => {
  const { t } = useTranslation(namespace)
  const copyContext = 'raf_modal'

  const referrerNames = React.useMemo((): string => {
    switch (referrerDogNumber) {
      case '0': {
        return t(`${copyContext}.title_long`, { referrerName })
      }
      case '1': {
        if (referrerName.length + referrerDogNames.length < 42) {
          return t(`${copyContext}.title_short`, {
            referrerName,
            referrerDogNames
          })
        }
        return t(`${copyContext}.title_long`, { referrerName })
      }
      case '2': {
        if (referrerName.length + referrerDogNames.length < 39) {
          return t(`${copyContext}.title_short_plural`, {
            referrerName,
            referrerDogNames
          })
        }
        return t(`${copyContext}.title_long_plural`, { referrerName })
      }
      default: {
        return t(`${copyContext}.title_long_plural`, { referrerName })
      }
    }
  }, [referrerDogNames, referrerDogNumber, referrerName, t])

  return (
    <div className="raf-modal__content__copy">
      <div className="raf-modal__content__heading">
        <h2 className="display-28">{referrerNames}</h2>
      </div>
      <div className="raf-modal__content__discount">
        <p className="text-regular-16">
          {<InjectedMarkupText text={rafMessage} />}
        </p>
        <button className="btn primary" onClick={closeRafModal} type="button">
          {t(`${copyContext}.button_text`)}
        </button>
      </div>
    </div>
  )
}

export default ClientReferralLinkContent
