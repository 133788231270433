// @flow

import { useState, useEffect } from 'react'

type WindowSize = {|
  windowWidth: number,
  windowHeight: number
|}

/**
 * A window size custom hook that can spy window resize changes.
 *
 * Destructuring showed in the example below is optional, you can
 * use returning value in form of an object or optinally pick only width or
 * height.
 *
 * Don't forget that hooks can only be called inside of the body of a
 * function component.
 *
 * Example usage
 * import useWindowSize from '@/hooks/useWindowSize'
 *
 * And inside of the body of a function component
 * const { windowWidth, windowHeight } = useWindowSize()
 */

const useWindowSize = (): WindowSize => {
  const isClient = typeof window === 'object'

  const getSize = (): WindowSize => ({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  })

  const [windowSize, setWindowSize] = useState(getSize())

  useEffect((): (() => void) | void => {
    const handleResize = (): void => {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    if (isClient) {
      return (): void => window.removeEventListener('resize', handleResize)
    }
  }, [isClient])

  return {
    windowWidth: windowSize.windowWidth,
    windowHeight: windowSize.windowHeight
  }
}

export default useWindowSize
