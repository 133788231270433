// @flow

type AnalyticsBackend = ((event: string, properties: {}) => void)
export type InteractionEventProperties = {|
  context: string,
  object: string,
  action: string
|}

export default function segmentTrack (
  eventName: string,
  properties: {} = {}): void {
  try {
    window.analytics.track(eventName, properties)
  } catch (error) {
    console.error(error)
  }
}

export class Analytics {
  static track (
    event: string,
    properties: {} = {},
    analyticsBackend: AnalyticsBackend = segmentTrack): void {
    try {
      analyticsBackend(event, properties)
    } catch (error) {
      console.error(error)
    }
  }

  static trackInteraction (
    event: string,
    interactionEventProperties: InteractionEventProperties,
    extraProperties: {} = {},
    analyticsBackend: AnalyticsBackend = segmentTrack): void {
    const properties = {
      ...interactionEventProperties,
      ...extraProperties
    }
    try {
      analyticsBackend(event, properties)
    } catch (error) {
      console.error(error)
    }
  }
}
