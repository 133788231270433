/* eslint-disable react/jsx-props-no-spreading */
import { ApolloProvider } from '@apollo/client'
import React from 'react'

import client from './client'

// In most standard ( non railsy ) react apps there is a root level App component
// in to which we can place the apollo wrapper. But we don't have that. As such we need to
// wrapp each page component in the apollo provider using this HOC

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function withApollo<P>(WrappedComponent: React.ComponentType<P>) {
  const ComponentWithApollo = (props: P) => (
    <ApolloProvider client={client}>
      <WrappedComponent {...props} />
    </ApolloProvider>
  )
  return ComponentWithApollo
}

export default withApollo
