// @noflow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToDefaultCurrencySymbol } from '@/utils/countryCodeHelper'

import segmentTrack from '@/components/analytics/Analytics'

import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'

import LocalisationModal from './LocalisationModal/localisationModal'
import {
  getCountryCode,
  getCountryFlag,
  getCountryName,
  getLanguage,
  getLanguageShortName
} from './localisationHelpers'

const baseClass = 'localisation-widget'

const triggerLocalisationAnalytics = (widgetLocation: string): void => {
  const eventName = 'Localisation Modal Opened'
  const properties = {
    widget_location: widgetLocation
  }
  segmentTrack(eventName, properties)
}

const LocalisationDisplay = ({
  activeShippingCountries,
  widgetLocation
}: {
  activeShippingCountries: Array<ShippingCountry>
  widgetLocation: string
}): React.ReactElement<'div'> => {
  const { t } = useTranslation(['shipping_countries', 'languages'])

  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [modalIsMounted, setModalIsMounted] = React.useState(false)

  const toggleModal = React.useCallback((): void => {
    setModalIsOpen(!modalIsOpen)
    setTimeout(
      (): void => {
        setModalIsMounted(!modalIsMounted)
        triggerLocalisationAnalytics(widgetLocation)
      },
      modalIsOpen ? 300 : 0
    )
  }, [
    modalIsOpen,
    setModalIsOpen,
    setModalIsMounted,
    modalIsMounted,
    widgetLocation
  ])

  const currentCountryCode = getCountryCode()
  const currentLanguage = getLanguage(
    currentCountryCode,
    activeShippingCountries
  )

  return (
    <React.Fragment>
      <button
        data-testid="geo-ip-widget"
        className={baseClass}
        type="button"
        onClick={toggleModal}
      >
        <img
          src={getCountryFlag(currentCountryCode) || ''}
          className={`${baseClass}__country-flag`}
          alt={`The flag of ${getCountryName({
            activeShippingCountries,
            countryCode: currentCountryCode,
            t
          })}`}
          height="20"
          width="20"
        />
        <span
          className={`${baseClass}__selected-country__name text-regular-18`}
        >
          {getCountryName({
            activeShippingCountries,
            countryCode: currentCountryCode,
            t
          }) || ''}
        </span>
        <span
          data-testid={`${baseClass}-selected-language`}
          className={`${baseClass}__selected-language text-regular-18`}
        >
          {getLanguageShortName({ language: currentLanguage, t }) || ''}
        </span>
        <span className={`${baseClass}__selected-currency text-regular-18`}>
          {countryCodeToDefaultCurrencySymbol(currentCountryCode)}
        </span>
      </button>
      {modalIsMounted && (
        <LocalisationModal
          currentLanguage={currentLanguage}
          currentCountryCode={currentCountryCode}
          activeShippingCountries={activeShippingCountries}
          closeModal={toggleModal}
          isOpen={modalIsOpen}
          widgetLocation={widgetLocation}
        />
      )}
    </React.Fragment>
  )
}

export default LocalisationDisplay
