// @noflow
import { makeVar } from '@apollo/client'
import React, { Fragment } from 'react'

import type { CookiePreferences } from '@/components/cookie_consent/index'
import CookieConsentBanner from '@/components/shared/CookieConsent/CookieConsentBanner'
import CookieConsentModal, {
  Variant
} from '@/components/shared/CookieConsent/Modals/CookieConsentModal'

type Props = {
  variant: Variant
  applyPreferences: (preferences: CookiePreferences) => void
}

const cookieConsentBannerState = makeVar({
  visible: false
})

const cookieConsentModalState = makeVar({
  visible: false
})

const CookieConsent = ({
  variant,
  applyPreferences
}: Props): JSX.Element | null => (
  <Fragment>
    <CookieConsentModal variant={variant} applyPreferences={applyPreferences} />
    <CookieConsentBanner applyPreferences={applyPreferences} />
  </Fragment>
)

export default CookieConsent
export { cookieConsentBannerState, cookieConsentModalState }
