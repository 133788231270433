// @noflow

type AgeCategory = 'puppy' | 'adult' | 'senior'

type DogSize =
  | 'extra_small'
  | 'small'
  | 'medium_1'
  | 'medium_2'
  | 'medium_3'
  | 'large_1'
  | 'large_2'
  | 'large_3'
  | 'large_4'
  | 'extra_large'

type EstimatedDailyPlanPrices = {
  ageCategory: AgeCategory
  size: DogSize
  GB: {
    mix: number
    all: number
  }
  NI: {
    mix: number
    all: number
  }
  IE: {
    mix: number
    all: number
  }
  NL: {
    mix: number
    all: number
  }
  BE: {
    mix: number
    all: number
  }
  PL: {
    mix: number
    all: number
  }
  DE: {
    mix: number
    all: number
  }
}

const EstimatedDailyPlanPrices: Array<EstimatedDailyPlanPrices> = [
  {
    ageCategory: 'puppy',
    size: 'extra_small',
    GB: {
      mix: 94.7,
      all: 107.5
    },
    NI: {
      mix: 94.7,
      all: 107.5
    },
    IE: {
      mix: 125.4,
      all: 152.0
    },
    NL: {
      mix: 183.0,
      all: 214.0
    },
    BE: {
      mix: 208.0,
      all: 181.0
    },
    PL: {
      mix: 554,
      all: 460.0
    },
    DE: {
      mix: 158.0,
      all: 191.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'small',
    GB: {
      mix: 103.3,
      all: 129.0
    },
    NI: {
      mix: 103.3,
      all: 129.0
    },
    IE: {
      mix: 127.0,
      all: 159.1
    },
    NL: {
      mix: 200.0,
      all: 211.0
    },
    BE: {
      mix: 216.0,
      all: 238.0
    },
    PL: {
      mix: 550.0,
      all: 590.0
    },
    DE: {
      mix: 198.0,
      all: 242.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'medium_1',
    GB: {
      mix: 119.3,
      all: 159.0
    },
    NI: {
      mix: 119.3,
      all: 159.0
    },
    IE: {
      mix: 90.4,
      all: 151.1
    },
    NL: {
      mix: 259.0,
      all: 256.0
    },
    BE: {
      mix: 222.0,
      all: 304.0
    },
    PL: {
      mix: 590.0,
      all: 895.0
    },
    DE: {
      mix: 210.0,
      all: 313.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'medium_2',
    GB: {
      mix: 145.5,
      all: 172.5
    },
    NI: {
      mix: 145.5,
      all: 172.5
    },
    IE: {
      mix: 169.6,
      all: 187.8
    },
    NL: {
      mix: 261.0,
      all: 303.0
    },
    BE: {
      mix: 286.0,
      all: 296.0
    },
    PL: {
      mix: 406.9,
      all: 855.85
    },
    DE: {
      mix: 232.0,
      all: 351.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'medium_3',
    GB: {
      mix: 144.7,
      all: 193.5
    },
    NI: {
      mix: 144.7,
      all: 193.5
    },
    IE: {
      mix: 189.8,
      all: 273.3
    },
    NL: {
      mix: 294.0,
      all: 296.0
    },
    BE: {
      mix: 346.0,
      all: 344.0
    },
    PL: {
      mix: 882.0,
      all: 837.8
    },
    DE: {
      mix: 273.0,
      all: 384.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'large_1',
    GB: {
      mix: 167.8,
      all: 198.5
    },
    NI: {
      mix: 167.8,
      all: 198.5
    },
    IE: {
      mix: 237.2,
      all: 254.9
    },
    NL: {
      mix: 340.0,
      all: 336.0
    },
    BE: {
      mix: 357.0,
      all: 415.0
    },
    PL: {
      mix: 755.9,
      all: 895.0
    },
    DE: {
      mix: 411.0,
      all: 321.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'large_2',
    GB: {
      mix: 193.0,
      all: 198.5
    },
    NI: {
      mix: 193.0,
      all: 198.5
    },
    IE: {
      mix: 180.9,
      all: 179.4
    },
    NL: {
      mix: 377.0,
      all: 354.0
    },
    BE: {
      mix: 342.0,
      all: 434.0
    },
    PL: {
      mix: 890.0,
      all: 1093.25
    },
    DE: {
      mix: 342.0,
      all: 424.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'large_3',
    GB: {
      mix: 198.55,
      all: 233.05
    },
    NI: {
      mix: 198.55,
      all: 233.05
    },
    IE: {
      mix: 435.2,
      all: 253.1
    },
    NL: {
      mix: 451.0,
      all: 389.0
    },
    BE: {
      mix: 460.0,
      all: 471.0
    },
    PL: {
      mix: 1025.0,
      all: 786.55
    },
    DE: {
      mix: 460.0,
      all: 644.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'large_4',
    GB: {
      mix: 309.35,
      all: 257.7
    },
    NI: {
      mix: 309.35,
      all: 257.7
    },
    IE: {
      mix: 294.8,
      all: 602.0
    },
    NL: {
      mix: 397.0,
      all: 554.0
    },
    BE: {
      mix: 354.0,
      all: 673.0
    },
    PL: {
      mix: 1390.0,
      all: 2190.0
    },
    DE: {
      mix: 354.0,
      all: 673.0
    }
  },
  {
    ageCategory: 'puppy',
    size: 'extra_large',
    GB: {
      mix: 305.0,
      all: 186.7
    },
    NI: {
      mix: 305.0,
      all: 186.7
    },
    IE: {
      mix: 350.1,
      all: 460.5
    },
    NL: {
      mix: 481.0,
      all: 512.0
    },
    BE: {
      mix: 435.0,
      all: 458.0
    },
    PL: {
      mix: 1657.0,
      all: 710.0
    },
    DE: {
      mix: 435.0,
      all: 458.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'extra_small',
    GB: {
      mix: 86.25,
      all: 86.0
    },
    NI: {
      mix: 86.25,
      all: 86.0
    },
    IE: {
      mix: 93.9,
      all: 105.3
    },
    NL: {
      mix: 175.0,
      all: 191.0
    },
    BE: {
      mix: 185.0,
      all: 178.0
    },
    PL: {
      mix: 550.0,
      all: 393.3
    },
    DE: {
      mix: 185.0,
      all: 165.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'small',
    GB: {
      mix: 86.0,
      all: 107.7
    },
    NI: {
      mix: 86.0,
      all: 107.7
    },
    IE: {
      mix: 121.0,
      all: 124.0
    },
    NL: {
      mix: 201.0,
      all: 224.0
    },
    BE: {
      mix: 179.0,
      all: 196.0
    },
    PL: {
      mix: 398.3,
      all: 530.0
    },
    DE: {
      mix: 189.0,
      all: 168.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'medium_1',
    GB: {
      mix: 96.5,
      all: 127.2
    },
    NI: {
      mix: 96.5,
      all: 127.2
    },
    IE: {
      mix: 116.0,
      all: 138.0
    },
    NL: {
      mix: 208.0,
      all: 235.0
    },
    BE: {
      mix: 215.0,
      all: 245.0
    },
    PL: {
      mix: 473.05,
      all: 630.0
    },
    DE: {
      mix: 192.0,
      all: 266.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'medium_2',
    GB: {
      mix: 107.5,
      all: 145.5
    },
    NI: {
      mix: 107.5,
      all: 145.5
    },
    IE: {
      mix: 116.0,
      all: 165.6
    },
    NL: {
      mix: 229.0,
      all: 245.0
    },
    BE: {
      mix: 221.0,
      all: 252.0
    },
    PL: {
      mix: 485.0,
      all: 750.0
    },
    DE: {
      mix: 228.0,
      all: 279.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'medium_3',
    GB: {
      mix: 116.0,
      all: 159.0
    },
    NI: {
      mix: 116.0,
      all: 159.0
    },
    IE: {
      mix: 143.2,
      all: 188.5
    },
    NL: {
      mix: 256.0,
      all: 263.0
    },
    BE: {
      mix: 240.0,
      all: 264.0
    },
    PL: {
      mix: 545.0,
      all: 820.0
    },
    DE: {
      mix: 317.0,
      all: 322.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'large_1',
    GB: {
      mix: 135.0,
      all: 173.0
    },
    NI: {
      mix: 135.0,
      all: 173.0
    },
    IE: {
      mix: 163.1,
      all: 186.0
    },
    NL: {
      mix: 258.0,
      all: 278.0
    },
    BE: {
      mix: 284.0,
      all: 309.0
    },
    PL: {
      mix: 745.0,
      all: 895.0
    },
    DE: {
      mix: 262.0,
      all: 335.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'large_2',
    GB: {
      mix: 136.5,
      all: 181.5
    },
    NI: {
      mix: 136.5,
      all: 181.5
    },
    IE: {
      mix: 173.5,
      all: 210.9
    },
    NL: {
      mix: 270.0,
      all: 310.0
    },
    BE: {
      mix: 309.0,
      all: 306.0
    },
    PL: {
      mix: 745.0,
      all: 1090.0
    },
    DE: {
      mix: 311.0,
      all: 334.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'large_3',
    GB: {
      mix: 136.5,
      all: 198.5
    },
    NI: {
      mix: 136.5,
      all: 198.5
    },
    IE: {
      mix: 196.3,
      all: 220.4
    },
    NL: {
      mix: 310.0,
      all: 309.0
    },
    BE: {
      mix: 305.0,
      all: 356.0
    },
    PL: {
      mix: 895.0,
      all: 1095.0
    },
    DE: {
      mix: 275.0,
      all: 442.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'large_4',
    GB: {
      mix: 159.9,
      all: 207.0
    },
    NI: {
      mix: 159.9,
      all: 207.0
    },
    IE: {
      mix: 196.2,
      all: 205.8
    },
    NL: {
      mix: 316.0,
      all: 326.0
    },
    BE: {
      mix: 337.0,
      all: 345.0
    },
    PL: {
      mix: 746.25,
      all: 951.0
    },
    DE: {
      mix: 337.0,
      all: 425.0
    }
  },
  {
    ageCategory: 'adult',
    size: 'extra_large',
    GB: {
      mix: 146.2,
      all: 230.0
    },
    NI: {
      mix: 146.2,
      all: 230.0
    },
    IE: {
      mix: 124.8,
      all: 276.0
    },
    NL: {
      mix: 344.0,
      all: 452.0
    },
    BE: {
      mix: 419.0,
      all: 380.0
    },
    PL: {
      mix: 795.0,
      all: 1095.0
    },
    DE: {
      mix: 347.0,
      all: 458.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'extra_small',
    GB: {
      mix: 67.7,
      all: 94.7
    },
    NI: {
      mix: 67.7,
      all: 94.7
    },
    IE: {
      mix: 152.0,
      all: 152.0
    },
    NL: {
      mix: 140.0,
      all: 193.0
    },
    BE: {
      mix: 140.0,
      all: 163.0
    },
    PL: {
      mix: 590.0,
      all: 426.1
    },
    DE: {
      mix: 316.0,
      all: 149.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'small',
    GB: {
      mix: 94.7,
      all: 110.0
    },
    NI: {
      mix: 94.7,
      all: 110.0
    },
    IE: {
      mix: 121.0,
      all: 124.0
    },
    NL: {
      mix: 191.0,
      all: 187.0
    },
    BE: {
      mix: 218.0,
      all: 207.0
    },
    PL: {
      mix: 553.75,
      all: 500.0
    },
    DE: {
      mix: 218.0,
      all: 189.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'medium_1',
    GB: {
      mix: 77.2,
      all: 125.5
    },
    NI: {
      mix: 77.2,
      all: 125.5
    },
    IE: {
      mix: 107.9,
      all: 147.7
    },
    NL: {
      mix: 206.0,
      all: 235.0
    },
    BE: {
      mix: 254.0,
      all: 247.0
    },
    PL: {
      mix: 428.6,
      all: 633.3
    },
    DE: {
      mix: 192.0,
      all: 282.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'medium_2',
    GB: {
      mix: 107.5,
      all: 136.13
    },
    NI: {
      mix: 107.5,
      all: 136.13
    },
    IE: {
      mix: 188.4,
      all: 189.9
    },
    NL: {
      mix: 250.0,
      all: 257.0
    },
    BE: {
      mix: 275.0,
      all: 330.0
    },
    PL: {
      mix: 655.0,
      all: 752.5
    },
    DE: {
      mix: 199.0,
      all: 282.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'medium_3',
    GB: {
      mix: 119.0,
      all: 158.9
    },
    NI: {
      mix: 119.0,
      all: 158.9
    },
    IE: {
      mix: 215.9,
      all: 264.3
    },
    NL: {
      mix: 264.0,
      all: 299.0
    },
    BE: {
      mix: 278.0,
      all: 335.0
    },
    PL: {
      mix: 571.875,
      all: 811.2
    },
    DE: {
      mix: 273.0,
      all: 327.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'large_1',
    GB: {
      mix: 103.3,
      all: 158.99
    },
    NI: {
      mix: 103.3,
      all: 158.99
    },
    IE: {
      mix: 169.3,
      all: 294.0
    },
    NL: {
      mix: 266.0,
      all: 357.0
    },
    BE: {
      mix: 329.0,
      all: 342.0
    },
    PL: {
      mix: 640.85,
      all: 1025.0
    },
    DE: {
      mix: 329.0,
      all: 366.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'large_2',
    GB: {
      mix: 159.0,
      all: 185.4
    },
    NI: {
      mix: 159.0,
      all: 185.4
    },
    IE: {
      mix: 172.0,
      all: 237.5
    },
    NL: {
      mix: 319.0,
      all: 342.0
    },
    BE: {
      mix: 413.0,
      all: 372.0
    },
    PL: {
      mix: 868.0,
      all: 1028.25
    },
    DE: {
      mix: 352.0,
      all: 341.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'large_3',
    GB: {
      mix: 180.5,
      all: 181.0
    },
    NI: {
      mix: 180.5,
      all: 181.0
    },
    IE: {
      mix: 213.3,
      all: 292.8
    },
    NL: {
      mix: 371.0,
      all: 421.0
    },
    BE: {
      mix: 297.0,
      all: 442.0
    },
    PL: {
      mix: 422.5,
      all: 810.0
    },
    DE: {
      mix: 218.0,
      all: 458.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'large_4',
    GB: {
      mix: 165.6,
      all: 145.5
    },
    NI: {
      mix: 165.6,
      all: 145.5
    },
    IE: {
      mix: 334.0,
      all: 237.0
    },
    NL: {
      mix: 458.0,
      all: 421.0
    },
    BE: {
      mix: 356.0,
      all: 425.0
    },
    PL: {
      mix: 895.0,
      all: 1095.0
    },
    DE: {
      mix: 355.0,
      all: 458.0
    }
  },
  {
    ageCategory: 'senior',
    size: 'extra_large',
    GB: {
      mix: 173.0,
      all: 161.875
    },
    NI: {
      mix: 173.0,
      all: 161.875
    },
    IE: {
      mix: 421.3,
      all: 308.4
    },
    NL: {
      mix: 395.0,
      all: 544.0
    },
    BE: {
      mix: 359.0,
      all: 232.8
    },
    PL: {
      mix: 1290.0,
      all: 837.8
    },
    DE: {
      mix: 334.0,
      all: 542.0
    }
  }
]

export default EstimatedDailyPlanPrices
export type { AgeCategory, EstimatedDailyPlanPrices }
