// @flow

import * as React from 'react'
import { Motion, spring } from 'react-motion'
import CloseButton from 'assets/images/icons/crosses/close.svg'
// eslint-disable-next-line flowtype/no-flow-fix-me-comments
// $FlowFixMe
import BREAKPOINTS from '@/constants/Breakpoints'

type Transition = 'left' | 'bottom' | 'scale' | 'right' | 'bottom-with-image'

type Props = {|
  modalIsOpen: boolean,
  showCloseButton: boolean,
  useCustomCloseButton?: boolean,
  customCloseButtonContent?: React.Node,
  onClose?: () => void,
  modalSize: 'small' | 'medium' | 'large',
  transitionTypes: {|desktop: Transition, mobile: Transition|},
  extraClasses: string,
  closeModal: (string) => void,
  children?: React.Node,
  withBackdrop?: boolean
|}

type MotionStyle = {|
  ...MotionStyle,
  'backdropOpacity'?: Array<number> | number,
  'opacity'?: Array<number> | number,
  'scale'?: Array<number> | number,
  'y'?: Array<number> | number,
  'x'?: Array<number> | number
|}

type interpolatedStyles = {|
  'defaultStyle': MotionStyle,
  'style': MotionStyle
|}

type State = {|
  className: string
|}

// eslint-disable-next-line react/require-optimization
export default class TransitionalModal extends React.Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  static onResizeModals = ['raf-modal']
  constructor (props: Props): void {
    super(props)
    this.state = {
      className: this.renderClassNames(),
    }
    this.checkWindowResize()
  }

  componentDidMount = (): void => {
    const { withBackdrop } = this.props
    if (document.body && withBackdrop !== false) document.body.classList.add('open-modal')
    if ('ontouchstart' in window) {
      document.addEventListener('touchstart', this.closeModalListener, false)
    } else {
      document.addEventListener('click', this.closeModalListener, false)
    }
  }

  checkWindowResize = (): void => {
    const { extraClasses } = this.props
    if (TransitionalModal.onResizeModals.includes(extraClasses)) {
      window.onresize = (): void => {
        this.setState({ className: this.renderClassNames() })
      }
    }
  }

  // eslint-disable-next-line react/no-arrow-function-lifecycle
  componentWillUnmount = (): void => {
    const { onClose } = this.props
    if (onClose) onClose()

    document.removeEventListener('click', this.closeModalListener, false)
    document.removeEventListener('touchstart', this.closeModalListener, false)
    if (document.body) document.body.classList.remove('open-modal')
    window.onresize = (): void => {} // stop listening to window resize
  }

  checkTransitionType = (): Transition => {
    const { transitionTypes } = this.props
    // $FlowFixMe
    const width = document.documentElement.clientWidth || document.body.clientWidth
    if (width > BREAKPOINTS.sm) return transitionTypes.desktop
    return transitionTypes.mobile
  }

  closeModalListener = (e: Event): void => {
    const { modalIsOpen, withBackdrop, extraClasses, closeModal } = this.props
    const { target } = e
    if (!(target instanceof window.HTMLElement)) return
    if (target.classList.contains('modal-container__backdrop') && modalIsOpen && withBackdrop !== false) {
      closeModal(extraClasses)
    }
  }

  modalClose = (): void => {
    const { extraClasses, closeModal } = this.props
    closeModal(extraClasses)
  }

  renderCloseButton = (): React.Node => {
    const { showCloseButton, useCustomCloseButton, customCloseButtonContent } = this.props
    if (!showCloseButton) {
      return null
    }
    if (useCustomCloseButton) {
      return (
        <button
          type='button'
          className='modal-container__modal__close-btn'
          onClick={this.modalClose}
        >
          { customCloseButtonContent }
        </button>
      )
    }
    return (
      <button
        type='button'
        className='modal-container__modal__close-btn'
        onClick={this.modalClose}
      >
        <img
          src={CloseButton}
          alt='A closing X icon.'
        />
      </button>
    )
  }

  getTransitionType = (): MotionStyle => {
    switch (this.checkTransitionType()) {
      case 'left':
        return { x: [-120, 0], y: [0, 0], scale: [1, 1], opacity: [0.8, 1], backdropOpacity: [0, 1] }
      case 'right':
        return { x: [100, 0], y: [0, 0], scale: [1, 1], opacity: [0.8, 1], backdropOpacity: [0, 1] }
      case 'bottom':
        return { x: [0, 0], y: [100, 0], scale: [1, 1], opacity: [0.8, 1], backdropOpacity: [0, 1] }
      case 'bottom-with-image':
        return { x: [0, 0], y: [130, 0], scale: [1, 1], opacity: [0.8, 1], backdropOpacity: [0, 1] }
      case 'scale':
        return { x: [-50, -50], y: [50, 50], scale: [0.5, 1], opacity: [0, 1], backdropOpacity: [0, 1] }
      default:
        return { x: [0, 0], y: [100, 0], scale: [1, 1], opacity: [0.8, 1], backdropOpacity: [0, 1] }
    }
  }

  interpolateStyles = (modalState: boolean): interpolatedStyles => {
    const transition = this.getTransitionType()
    let config = { stiffness: 250, damping: 25 }
    if (transition === 'left' || transition === 'right') {
      config = { stiffness: 200, damping: 30 }
    }

    // $FlowFixMe
    const styles = Object.keys(transition)
    const defaultStyle = {}
    const interpolated = {}
    const formatted = styles.map((item: string, index: number): Array<MotionStyle> => {
      // $FlowFixMe
      defaultStyle[`${item}`] = transition[`${item}`][0]
      // $FlowFixMe
      interpolated[`${item}`] = spring(modalState ? transition[`${item}`][1] : transition[`${item}`][0], config)
      return [defaultStyle, interpolated]
    })
    return {
      defaultStyle: formatted[styles.length - 1][0],
      style: formatted[styles.length - 1][1]
    }
  }

  renderClassNames = (): string => {
    const { modalSize, extraClasses } = this.props
    let modalClasses = 'modal-container__modal white-background__medium modal-container__modal'
    if (modalSize) modalClasses += `--${modalSize}`
    if (extraClasses) modalClasses += ` ${extraClasses}`
    if (this.checkTransitionType()) modalClasses += ` modal-container__modal--${this.checkTransitionType()}`
    return modalClasses
  }

  render (): React.Node {
    const { modalIsOpen, withBackdrop, children } = this.props
    const { className } = this.state
    const defaultStyle = this.interpolateStyles(modalIsOpen).defaultStyle
    const style = this.interpolateStyles(modalIsOpen).style
    return (
      <Motion
        defaultStyle={defaultStyle}
        style={style}
      >
        { (style: MotionStyle): React.Node => (
          <div className='modal-container'>
            {
              withBackdrop !== false &&
                <div
                  className='modal-container__backdrop'
                  style={{ opacity: style.backdropOpacity }}
                />
            }
            <div
              style={{
                transform: `translate3d(${Number(style.x).toString()}%, ${Number(style.y).toString()}%, 0) scale(${Number(style.scale).toString()})`,
                opacity: style.opacity
              }}
              className={className}
            >
              { this.renderCloseButton() }
              { children }
            </div>
          </div>
        ) }
      </Motion>
    )
  }
}
