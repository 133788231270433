// @noflow
import { ageOptions } from '@/static_content/dropdownOptions'
import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Select from '@/components/shared/elements/Select/Select'
import type { Option } from '@/components/shared/elements/Select/Select'

import { dogDetailsState } from './DogDetailsForm'

const namespace = 'homepage'
const copyContext = 'rough_cost_calculator'
const baseClass = 'rough-cost-calculator__cost-calculator'

const AgeSelect = (): JSX.Element => {
  const { t } = useTranslation(namespace)

  const dogDetails = useReactiveVar(dogDetailsState)

  const formattedAgeOptions = ageOptions.map((option, index): Option => {
    return {
      id: index.toString(),
      value: option.value,
      label: t(`${copyContext}.age.${option.text}`)
    }
  })

  const formattedAgeOption = formattedAgeOptions.find(
    (option) => option.value === dogDetails.age
  )

  const handleAgeChange = useCallback(
    (option: Option) => {
      // We want to set interactedWithAge explicitly as true so that we don't end up using the
      // defaulted value of 2 years old in the Signup Wizard if this isn't a value picked by the user
      dogDetailsState({
        ...dogDetails,
        age: parseInt(option.value.toString()),
        interactedWithAge: true
      })
    },
    [dogDetails]
  )

  return (
    <div className={`${baseClass}__dropdown-wrapper`}>
      <Select
        onChange={handleAgeChange}
        label={t(`${copyContext}.age_label`)}
        options={formattedAgeOptions}
        initialValue={formattedAgeOption}
      />
    </div>
  )
}

export default AgeSelect
