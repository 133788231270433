import { gql } from '@apollo/client'

const HOME_BREED_SELECTOR_QUERY = gql`
  query HomeBreedSelectorQuery($name: String, $popular: Boolean) {
    breeds(name: $name, popular: $popular) {
      id
      name
      key
      popular
    }
  }
`

export { HOME_BREED_SELECTOR_QUERY }
