// @noflow
import { useCallback, useState } from 'react'

type UseBoolean = {
  value: boolean
  setValue: (value: boolean) => void
  toggle: () => void
  setTrue: () => void
  setFalse: () => void
}

const useBoolean = (initial: boolean): UseBoolean => {
  const [value, setValue] = useState<boolean>(initial)

  const toggle = useCallback((): void => {
    setValue((val: boolean): boolean => !val)
  }, [])

  const setTrue = useCallback((): void => {
    setValue(true)
  }, [])

  const setFalse = useCallback((): void => {
    setValue(false)
  }, [])

  return {
    value,
    setValue,
    toggle,
    setTrue,
    setFalse
  }
}

export type { UseBoolean }

export default useBoolean
