// @noflow
import { ReactiveVar, makeVar } from '@apollo/client'

import type {
  ClientInitQuery_user_dogs as Dog,
  ClientInitQuery_user_shippingCountry as ShippingCountry,
  ClientInitQuery_user_subscription as Subscription,
  ClientInitQuery_user as User
} from '@/components/pages/App/queries/__generated__/ClientInitQuery'

import { SubscriptionStatus } from '@/types/index'

type DynamicDefaultTrackEventProperties = {
  subscription_id?: string
  subscription_status?: SubscriptionStatus
  deliveries_received?: number
  has_puppy?: boolean
  has_fussy_eater?: boolean
}

type DogInfo = {
  dogs: Array<Dog>
  pronoun: 'male' | 'female' | 'plural'
  possessive: Record<string, string>
  has_puppy: boolean
  has_fussy_eater: boolean
}

const dynamicDefaultTrackEventPropertiesVar: ReactiveVar<DynamicDefaultTrackEventProperties | null> =
  makeVar<DynamicDefaultTrackEventProperties | null>(null)

const userDataVar: ReactiveVar<User | null> = makeVar<User | null>(null)

const dogsDataVar: ReactiveVar<DogInfo | null> = makeVar<DogInfo | null>(null)

const subscriptionDataVar: ReactiveVar<Subscription | null> =
  makeVar<Subscription | null>(null)

const shippingCountryDataVar: ReactiveVar<ShippingCountry | null> =
  makeVar<ShippingCountry | null>(null)

const featureFlagsDataVar: ReactiveVar<Record<string, string | null> | null> =
  makeVar<Record<string, string | null> | null>(null)

const hasNotificationsVar: ReactiveVar<boolean> = makeVar<boolean>(false)

const settingsVar: ReactiveVar<Record<string, boolean>> = makeVar<
  Record<string, boolean>
>({})

export type { DynamicDefaultTrackEventProperties, DogInfo, ShippingCountry }

export {
  dynamicDefaultTrackEventPropertiesVar,
  dogsDataVar,
  userDataVar,
  subscriptionDataVar,
  shippingCountryDataVar,
  featureFlagsDataVar,
  hasNotificationsVar,
  settingsVar
}
