// @noflow
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { BreedSelectorQuery_breeds as Breed } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'
import BreedAutocomplete from '@/components/shared/elements/BreedSelector/components/BreedAutocomplete/BreedAutocomplete'

import { BreedSelectorVariant } from '../../BreedSelector/BreedSelector'

type Props = {
  breedSelectRef?: React.RefObject<HTMLDivElement>
  onFocusCallback?: () => void
}

const namespace = 'homepage'
const copyContext = 'rough_cost_calculator'
const baseClass = 'rough-cost-calculator__cost-calculator'

const BreedSelect = ({
  breedSelectRef,
  onFocusCallback
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  const onBreedSelectionCallback = useCallback((breed: Breed) => {
    // Set Breed ID cookie to be accessed by the Wizard to allow
    // for pre-populated breed selection
    Cookies.set(
      'breedSelectorBreedId',
      JSON.stringify({
        id: parseInt(breed.id),
        key: breed.key,
        name: breed.name
      })
    )
  }, [])

  return (
    <div className={`${baseClass}__breed-row`}>
      <p className={`text-regular-18 no-scale breed-label`}>
        {t(`${copyContext}.breed_label`)}
      </p>
      <div className={`${baseClass}__breed-autocomplete`} ref={breedSelectRef}>
        <BreedAutocomplete
          loadingPlaceholder={t(`${copyContext}.breed_loading`)}
          placeholder={t(`${copyContext}.breed_placeholder`)}
          events={{
            onBreedSelection: onBreedSelectionCallback,
            onListOpen: () => null,
            onFocus: onFocusCallback || (() => null),
            onInputEmpty: () => null
          }}
          variant={BreedSelectorVariant.Generic}
        />
      </div>
    </div>
  )
}

export default BreedSelect
