// @noflow
import fetchActiveShippingCountries from '@/helpers/fetchActiveShippingCountries'
import i18next from 'i18next'
import * as React from 'react'
import ReactDOM from 'react-dom'

import LocalisationDisplay from '@/components/shared/geo_ip_widget/localisationDisplay'

const initGeoIpWidget = async ({
  desktopNav,
  mobileSidebar,
  contentTagElementId,
  csrfToken
}: {
  desktopNav: boolean
  mobileSidebar: boolean
  contentTagElementId?: string
  csrfToken?: string
}): Promise<void> => {
  let token = ''

  if (csrfToken) {
    token = csrfToken
  } else {
    if (contentTagElementId) {
      const contentTagElement = document.getElementById(contentTagElementId)
      if (!contentTagElement)
        throw new Error(
          `Could not find contentTagElementId: ${contentTagElementId}`
        )

      const { csrfToken } = contentTagElement.dataset
      if (!csrfToken)
        throw new Error(
          `Could not find csrfToken for contentTagElement ${contentTagElement}`
        )
      token = csrfToken
    } else throw new Error('Could not find csrfToken or contentTagElementId')
  }

  const activeShippingCountries = await fetchActiveShippingCountries({
    csrfToken: token
  })

  const initGeoIpWidgetDesktopNav = (): void => {
    const geoIpWidgetElement = document.querySelector(
      '.desktop-nav-localisation-widget'
    )
    if (!geoIpWidgetElement)
      throw new Error('Could not find geoIpWidgetElement in desktop navigation')

    ReactDOM.render(
      <LocalisationDisplay
        activeShippingCountries={activeShippingCountries}
        widgetLocation="Desktop Nav"
      />,
      geoIpWidgetElement
    )
  }

  const initGeoIpWidgetMobileSidebar = (): void => {
    const geoIpWidgetElement = document.querySelector(
      '.mobile-sidebar-localisation-widget-wrapper'
    )
    if (!geoIpWidgetElement)
      throw new Error('Could not find geoIpWidgetElement in mobile sidebar')

    const copyContext = 'navigation:geo_ip_widget'

    ReactDOM.render(
      <React.Fragment>
        <hr className="slide-out-nav__content__divider" />
        <div className="mobile-sidebar-localisation-widget-wrapper">
          <p className="text-regular-18">
            {i18next.t(`${copyContext}.your_location`)}
          </p>
          <LocalisationDisplay
            activeShippingCountries={activeShippingCountries}
            widgetLocation="Mobile Sidebar"
          />
        </div>
      </React.Fragment>,
      geoIpWidgetElement
    )
  }

  const geoLocationCheckElement = document.querySelector(
    '.desktop-nav-localisation-widget'
  )
  if (!geoLocationCheckElement)
    throw new Error('Could not find geoLocationCheckElement')

  if (desktopNav) {
    initGeoIpWidgetDesktopNav()
  }
  if (mobileSidebar) {
    initGeoIpWidgetMobileSidebar()
  }
}

export default initGeoIpWidget
