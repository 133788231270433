// @noflow
import React, { useCallback } from 'react'

import { UserType, wizardCtaLink } from '@/utils/wizardCtaLink'

import { Button } from '@/components/shared/elements/Button/Button'

type Props = {
  namespace: string
  text: string
  currentUserType?: UserType
  screenIdentifier: string
}

const CTA = ({
  currentUserType,
  screenIdentifier,
  namespace,
  text
}: Props): JSX.Element => {
  const handleCTAClick = useCallback(() => {
    window.location.href = currentUserType
      ? wizardCtaLink(currentUserType).url
      : '/wizard/new'
  }, [currentUserType])

  return (
    <Button
      typography={{
        text,
        namespace
      }}
      onClick={handleCTAClick}
      identifier="rough_cost_calculator_cta"
      screenIdentifier={screenIdentifier}
      fullWidth
    />
  )
}

export default CTA
