// @noflow
import type { AgeCategory } from '@/static_content/EstimatedDailyPlanPrices'

const ageToAgeCategory = (dogAge: number): AgeCategory => {
  switch (dogAge) {
    case 0: {
      return 'puppy'
    }
    case 12: {
      return 'senior'
    }
    default: {
      return 'adult'
    }
  }
}

export { ageToAgeCategory }
