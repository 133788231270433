// @noflow
import type { Language } from '@/packs/localisation'
import Cookies from 'js-cookie'
import * as React from 'react'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import AmbassadorReferralLinkContent from './components/AmbassadorReferralLinkContent'
import ClientReferralLinkContent from './components/ClientReferralLinkContent'
import TransitionalModal from '@/components/shared/TransitionalModal'
import VideoElement from '@/components/shared/VideoElement'

import type { Basis as DiscountBasis } from '@/shared_types/rails_models/discounts.js'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'
import { ensureNever } from '@/typescript/utils'

type Profession =
  | 'vet'
  | 'trainer'
  | 'nutritionist'
  | 'behaviourist'
  | 'vet_nurse'
  | 'groomer'
  | 'breeder'
  | 'doggy_day_care'
  | 'dog_walker'
  | 'charity'
  | 'rescue_centre'
  | 'pet_accessories'
  | 'restaurant'
  | 'dog_show'
  | 'dog_blog'
  | 'dog_therapist'
  | 'other'

type ClientReferralLinkProps = {
  referrerName: string
  referrerDogNames: string
  referrerDogNumber: string
  referralLinkType: 'ClientReferralLink'
  rafMessage: string
  refereeNextBoxDiscountBasis: DiscountBasis
  refereeNextBoxDiscountValue: string
}

type AmbassadorReferralLinkProps = {
  referrerName: string
  profession: Profession
  companyName: string
  referralLinkType: 'AmbassadorReferralLink'
  refereeDiscountMessage: string
  refereeNextBoxDiscountBasis: DiscountBasis
  refereeNextBoxDiscountValue: string
}

type RafModalData = ClientReferralLinkProps | AmbassadorReferralLinkProps

type Props = {
  rafModalData: RafModalData
}

const RafModal = (props: Props): ReactElement | null => {
  const [rafModalIsMounted, setRafModalIsMounted] = useState(false)
  const [rafModalIsOpen, setRafModalIsOpen] = useState(false)

  const namespace = 'homepage'
  const copy_context = 'raf_modal'
  const { t } = useTranslation(namespace)

  const closeRafModal = useCallback((): void => {
    Cookies.set('has_dismissed_home_raf_modal', 'true')
    setRafModalIsOpen(false)
    // Unlocks background body-scroll
    document.body.classList.remove('modal--open')

    setTimeout((): void => {
      setRafModalIsMounted(false)
    }, 300)
  }, [])

  const { rafModalData } = props

  const nextBoxDiscountValue = useMemo(() => {
    const { refereeNextBoxDiscountBasis, refereeNextBoxDiscountValue } =
      rafModalData

    const shippingCountryCode = Cookies.get('user_country_code') || 'GB'
    const language = Cookies.get('user_language') || 'en'

    const { locale, currency } = countryCodeToLocaleCurrency(
      shippingCountryCode as CountryCode,
      language as Language
    )

    return refereeNextBoxDiscountBasis === 'percentage'
      ? percentageValue(refereeNextBoxDiscountValue)
      : formatCurrencyWithoutDecimal(parseInt(refereeNextBoxDiscountValue), {
          locale: locale,
          currency: currency
        })
  }, [rafModalData])

  const RafModalContent = useMemo(() => {
    const { referralLinkType } = rafModalData
    switch (referralLinkType) {
      case 'ClientReferralLink': {
        const {
          rafMessage,
          referrerName,
          referrerDogNames,
          referrerDogNumber
        } = rafModalData
        return (
          <ClientReferralLinkContent
            namespace={namespace}
            closeRafModal={closeRafModal}
            rafMessage={rafMessage}
            referrerName={referrerName}
            referrerDogNames={referrerDogNames}
            referrerDogNumber={referrerDogNumber}
          />
        )
      }
      case 'AmbassadorReferralLink': {
        const {
          referrerName,
          profession,
          companyName,
          refereeDiscountMessage
        } = rafModalData
        return (
          <AmbassadorReferralLinkContent
            namespace={namespace}
            closeRafModal={closeRafModal}
            referrerName={referrerName}
            profession={profession}
            companyName={companyName}
            refereeDiscountMessage={refereeDiscountMessage}
          />
        )
      }
      default: {
        ensureNever(referralLinkType)
        throw new Error(
          `Could not generate RafModalContent for referralLinkType of: ${referralLinkType}`
        )
      }
    }
  }, [closeRafModal, rafModalData])

  useEffect((): void => {
    setRafModalIsMounted(true)
    // Locks background body-scroll
    document.body.classList.add('modal--open')
    setTimeout((): void => {
      setRafModalIsOpen(true)
    }, 1000)
  }, [])

  if (!rafModalIsMounted) return null

  return (
    <TransitionalModal
      closeModal={closeRafModal}
      extraClasses="raf-modal-wrapper"
      modalIsOpen={rafModalIsOpen}
      modalSize="large"
      showCloseButton
      transitionTypes={{ desktop: 'scale', mobile: 'bottom' }}
    >
      <div className="raf-modal__container">
        <div className="raf-modal__video">
          <div className="raf-modal__video__crop">
            <VideoElement
              video={{
                path: 'Web/videos/dogs/dogs-with-bowls',
                width: 400,
                crop: 'fill',
                dpr: window.devicePixelRatio
              }}
              preload="auto"
              className="lazy"
              muted
              controls={false}
              autoPlay
              loop
              playsInline
              alt={t(`${copy_context}.image_alt`)}
            />
          </div>
          <div className="raf-modal__ribbon-wrapper">
            <div className="raf-modal__ribbon-container">
              <div className="raf-modal__ribbon" />
              <div className="raf-modal__ribbon__text">
                <p className="display-20">
                  {t(`${copy_context}.ribbon_html`, {
                    value: nextBoxDiscountValue
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
        {RafModalContent}
      </div>
    </TransitionalModal>
  )
}

export type {
  Profession,
  ClientReferralLinkProps,
  AmbassadorReferralLinkProps,
  RafModalData
}
export default RafModal
