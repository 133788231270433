// @noflow
import type { Language } from '@/packs/localisation'
import Cookies from 'js-cookie'
import * as React from 'react'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithoutDecimal } from '@/utils/currency'
import { percentageValue } from '@/utils/percentage'

import useWindowSize from '@/hooks/useWindowSize'

import TransitionalModal from '@/components/shared/TransitionalModal'
import { BREAKPOINTS } from '@/components/templates/Base'

import type { Basis as DiscountBasis } from '@/shared_types/rails_models/discounts.js'
import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import Image from '../Image'

type RafCampaignModalData = {
  referrerName: string
  referrerDogNames: string
  referrerDogNumber: string
  referralLinkType: 'ClientReferralLink'
  rafMessage: string
  refereeNextBoxDiscountBasis: DiscountBasis
  refereeNextBoxDiscountValue: string
}

type Props = {
  rafCampaignModalData: RafCampaignModalData
}

const RafCampaignModal = (props: Props): ReactElement | null => {
  const { windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.md

  const [rafCampaignModalIsMounted, setRafCampaignModalIsMounted] =
    useState(false)
  const [rafCampaignModalIsOpen, setRafCampaignModalIsOpen] = useState(false)

  const namespace = 'homepage'
  const copyContext = 'raf_campaign_modal'
  const { t } = useTranslation(namespace)

  const closeRafCampaignModal = useCallback((): void => {
    Cookies.set('has_dismissed_home_raf_modal', 'true')
    setRafCampaignModalIsOpen(false)
    // Unlocks background body-scroll
    document.body.classList.remove('modal--open')

    setTimeout((): void => {
      setRafCampaignModalIsMounted(false)
    }, 300)
  }, [])

  const { rafCampaignModalData } = props
  const { referrerName, referrerDogNames, referrerDogNumber } =
    rafCampaignModalData

  const nextBoxDiscountValue = useMemo(() => {
    const { refereeNextBoxDiscountBasis, refereeNextBoxDiscountValue } =
      rafCampaignModalData

    const shippingCountryCode = Cookies.get('user_country_code') || 'GB'
    const language = Cookies.get('user_language') || 'en'

    const { locale, currency } = countryCodeToLocaleCurrency(
      shippingCountryCode as CountryCode,
      language as Language
    )

    return refereeNextBoxDiscountBasis === 'percentage'
      ? percentageValue(refereeNextBoxDiscountValue)
      : formatCurrencyWithoutDecimal(parseInt(refereeNextBoxDiscountValue), {
          locale: locale,
          currency: currency
        })
  }, [rafCampaignModalData])

  const headingCopy = React.useMemo((): string => {
    switch (referrerDogNumber) {
      case '0': {
        return t(`${copyContext}.title_long`, { referrerName })
      }
      case '1': {
        if (referrerName.length + referrerDogNames.length < 42) {
          return t(`${copyContext}.title_short`, {
            referrerName,
            referrerDogNames
          })
        }
        return t(`${copyContext}.title_long`, { referrerName })
      }
      case '2': {
        if (referrerName.length + referrerDogNames.length < 39) {
          return t(`${copyContext}.title_short_plural`, {
            referrerName,
            referrerDogNames
          })
        }
        return t(`${copyContext}.title_long_plural`, { referrerName })
      }
      default: {
        return t(`${copyContext}.title_long_plural`, { referrerName })
      }
    }
  }, [referrerDogNames, referrerDogNumber, referrerName, t])

  useEffect((): void => {
    setRafCampaignModalIsMounted(true)
    // Locks background body-scroll
    document.body.classList.add('modal--open')
    setTimeout((): void => {
      setRafCampaignModalIsOpen(true)
    }, 1000)
  }, [])

  if (!rafCampaignModalIsMounted) return null

  return (
    <TransitionalModal
      closeModal={closeRafCampaignModal}
      extraClasses="raf-campaign-modal-wrapper"
      modalIsOpen={rafCampaignModalIsOpen}
      modalSize="large"
      showCloseButton
      transitionTypes={{ desktop: 'scale', mobile: 'bottom' }}
    >
      <div className="raf-campaign-modal__container">
        <div className="raf-campaign-modal__image">
          <Image
            slug="ifd-modal-hero"
            image={{
              width: 400,
              height: 600,
              resizeMode: 'resize_to_fill',
              gravity: isMobile ? 'center' : 'south'
            }}
            alt={t(`${copyContext}.image_alt`)}
          />
          <div className="raf-campaign-modal__ribbon-wrapper">
            <div className="raf-campaign-modal__ribbon-container">
              <div className="raf-campaign-modal__ribbon" />
              <div className="raf-campaign-modal__ribbon__text">
                <p className="display-20">
                  {t(`${copyContext}.ribbon_html`, {
                    value: nextBoxDiscountValue
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="raf-campaign-modal__content__copy">
          <div className="raf-campaign-modal__content__heading">
            <h2 className="display-28">{headingCopy}</h2>
          </div>
          <div className="raf-campaign-modal__content__discount">
            <p className="text-regular-16">
              {t(`${copyContext}.description`, {
                value: nextBoxDiscountValue
              })}
            </p>
            <button
              className="btn primary"
              onClick={closeRafCampaignModal}
              type="button"
            >
              {t(`${copyContext}.button_text`)}
            </button>
          </div>
        </div>
      </div>
    </TransitionalModal>
  )
}

export type { RafCampaignModalData }
export default RafCampaignModal
