// @noflow
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import isUndefined from 'lodash/isUndefined'
import React from 'react'

import { BrandColours } from '@/constants/BrandColours'
import BREAKPOINTS from '@/constants/Breakpoints'

import STYLES from './Base.module.sass'

const theme = createTheme({
  breakpoints: {
    values: {
      ...BREAKPOINTS
    }
  }
})

type Props = {
  background?:
    | keyof Pick<
        BrandColours,
        'brandWhite' | 'brandYellow100' | 'brandYellow200'
      >
    | 'transparent'
  children: React.ReactNode
}

const Base = ({ background, children }: Props): JSX.Element => (
  <div className={!isUndefined(background) ? STYLES[background] : ''}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </div>
)

export { BREAKPOINTS, Props, theme }
export default Base
