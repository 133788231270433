// @flow

/**
 * Support Colours
 * Here is where we define our core support colours in JavaScript variables so we
 * can pass them as parameters into our asset functions that are stored inside
 * 'javascript/assets/...'. These colours should ALWAYS be in-line with the
 * variables stored in our `_colours.scss` CSS sheet.
 */

type SupportColours = {|
  // Blue
  supportBlue100: '#f1fcfd',
  supportBlue200: '#beebf4',
  supportBlue300: '#0bb4d0',
  supportBlue400: '#078aa2',
  supportBlue500: '#05697a',

  // Green
  successGreen100: '#eff8ed',
  successGreen200: '#98d98c',
  successGreen300: '#4dbd37',
  successGreen400: '#238a0f',
  successGreen500: '#1f6412',

  // Red
  dangerRed100: '#fdf2f1',
  dangerRed200: '#f5bebc',
  dangerRed300: '#d2322d',
  dangerRed400: '#9d100b',
  dangerRed500: '#7a0a06',

  // Orange
  warningOrange100: '#fcf2e8',
  warningOrange200: '#f9c794',
  warningOrange300: '#f6820e',
  warningOrange400: '#c60',
  warningOrange500: '#66401a',

  // Grey
  grey100: '#f7f5f3',
  grey200: '#e7e6e4',
  grey300: '#cfccc9',
  grey400: '#b6b3af',
  grey500: '#9c9896',
  grey600: '#696563',

  // Shadows
  shadowYellow: '#f6d17d',

  // Boost
  boostLight: '#936755',
  boostDark: '#432918'
|}

const SUPPORT_COLOURS: SupportColours = {
  // Blue
  supportBlue100: '#f1fcfd',
  supportBlue200: '#beebf4',
  supportBlue300: '#0bb4d0',
  supportBlue400: '#078aa2',
  supportBlue500: '#05697a',

  // Green
  successGreen100: '#eff8ed',
  successGreen200: '#98d98c',
  successGreen300: '#4dbd37',
  successGreen400: '#238a0f',
  successGreen500: '#1f6412',

  // Red
  dangerRed100: '#fdf2f1',
  dangerRed200: '#f5bebc',
  dangerRed300: '#d2322d',
  dangerRed400: '#9d100b',
  dangerRed500: '#7a0a06',

  // Orange
  warningOrange100: '#fcf2e8',
  warningOrange200: '#f9c794',
  warningOrange300: '#f6820e',
  warningOrange400: '#c60',
  warningOrange500: '#66401a',

  // Grey
  grey100: '#f7f5f3',
  grey200: '#e7e6e4',
  grey300: '#cfccc9',
  grey400: '#b6b3af',
  grey500: '#9c9896',
  grey600: '#696563',

  // Shadows
  shadowYellow: '#f6d17d',

  // Boost
  boostLight: '#936755',
  boostDark: '#432918'
}

export default SUPPORT_COLOURS

export type { SupportColours }
