// @flow

import type { Locale } from './countryCodeHelper'

type Currency =
  | 'GBP'
  | 'EUR'
  | 'PLN'

type Options = {|
  currency: Currency,
  locale: Locale
|}

// Default currency and locale
const DEFAULT_CURRENCY: Currency = 'GBP'
const DEFAULT_LOCALE: Locale = 'en-GB'

const DEFAULT_OPTIONS: Options = {
  currency: DEFAULT_CURRENCY,
  locale: DEFAULT_LOCALE
}

/**
 * See the reference guide for `toLocaleString`:
 * developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 *
 * eg: formatCurrencyWithDecimal(100) // £1.00
 */
const formatCurrencyWithDecimal = (
  n: number,
  { locale, currency }: Options = DEFAULT_OPTIONS
): string => {
  return (n / 100).toLocaleString(
    locale,
    {
      style: 'currency',
      currency,
      maximumFractionDigits: 2
    }
  )
}

/**
 * See the reference guide for `toLocaleString`:
 * developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 *
 * eg: formatCurrencyWithoutDecimal(100) // £1
 */
const formatCurrencyWithoutDecimal = (
  n: number,
  { locale, currency }: Options = DEFAULT_OPTIONS
): string => {
  return (n / 100).toLocaleString(
    locale,
    {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  )
}


export {
  formatCurrencyWithDecimal,
  formatCurrencyWithoutDecimal,
}
