// @noflow
import { useReactiveVar } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from 'assets/images/icons/crosses/close-icon--white.svg'

import { Analytics } from '@/components/analytics/Analytics'
import type { CookiePreferences } from '@/components/cookie_consent/index'
import { actionToCookieConsent } from '@/components/shared/CookieConsent/helpers'
import { Action } from '@/components/shared/CookieConsent/types'

import {
  cookieConsentBannerState,
  cookieConsentModalState
} from './CookieConsent'

type Props = {
  applyPreferences: (preferences: CookiePreferences) => void
}

const CookieConsentBanner = ({ applyPreferences }: Props): JSX.Element => {
  const { t } = useTranslation('shared')
  const copyContext = 'cookie_consent.banner'
  const cookieConsentBanner = useReactiveVar(cookieConsentBannerState)

  const [isBannerVisible, setIsBannerVisible] = useState(
    cookieConsentBanner.visible
  )

  const closeBanner = useCallback(() => {
    setIsBannerVisible(false)

    setTimeout(() => {
      cookieConsentBannerState({ visible: false })
    }, 300)
  }, [])

  const handleOnClose = useCallback(() => {
    actionToCookieConsent({ action: Action.Accepted, applyPreferences })

    Analytics.track('Cookie Preferences Saved', {
      preferences: { marketing: true, analytics: true },
      context: 'banner close button'
    })

    closeBanner()
  }, [applyPreferences, closeBanner])

  const handleOnManageClick = useCallback(() => {
    cookieConsentModalState({ visible: true })
    actionToCookieConsent({ action: Action.None })
  }, [])

  const handleOnAcceptClick = useCallback(() => {
    actionToCookieConsent({ action: Action.Accepted, applyPreferences })

    Analytics.track('Cookie Preferences Saved', {
      preferences: { marketing: true, analytics: true },
      // eslint-disable-next-line quotes
      context: "banner 'got it' button"
    })

    closeBanner()
  }, [applyPreferences, closeBanner])

  // Ensure the banner local state is kept up to date as other side effects
  // are called changing the value of cookieConsentBanner.visible
  useEffect(
    () => setIsBannerVisible(cookieConsentBanner.visible),
    [cookieConsentBanner.visible]
  )

  return (
    <div>
      <div
        className={`cookie-banner ${isBannerVisible ? 'transition-in' : ''}`}
      >
        <button
          className="cookie-banner__close"
          type="button"
          onClick={handleOnClose}
        >
          <img src={CloseIcon} alt={t(`${copyContext}.close_btn_alt`)} />
        </button>
        <h2 className="cookie-banner__title">{t(`${copyContext}.title`)}</h2>
        <div className="cookie-banner__content">
          <p>{t(`${copyContext}.description`)}</p>
          <div className="cookie-banner__buttons">
            <button
              className="cookie-banner__buttons__manage-button"
              type="button"
              onClick={handleOnManageClick}
            >
              {t(`${copyContext}.manage_btn`)}
            </button>
            <button
              id="cookie-banner-accept-button"
              data-testid="cookie-accept-button"
              className="btn secondary cookie-banner__buttons__accept-button"
              type="button"
              onClick={handleOnAcceptClick}
            >
              {t(`${copyContext}.accept_btn`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieConsentBanner
