// @noflow
import type { CookiePreferences } from '@/components/cookie_consent/index'

enum Action {
  Accepted = 'accepted',
  None = 'none',
  Cancelled = 'cancelled',
  Saved = 'saved',
  Deny = 'deny'
}

type LocalStorageCookieConsent = {
  action: Action
  preferences?: CookiePreferences
}

export { Action }
export type { LocalStorageCookieConsent }
