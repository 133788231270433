// @noflow
import type { Language } from '@/packs/localisation'
import EstimatedDailyPlanPrices from '@/static_content/EstimatedDailyPlanPrices'
import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { DiscountBasis } from '@/utils/butternutbox/discountCodes'
import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'
import * as Sentry from '@/utils/sentry'

import Image from '@/components/shared/elements/Image/Image'
import { ageToAgeCategory } from '@/components/shared/elements/RoughCostCalculator/helpers/roughCostCalculatorHelpers'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import { dogDetailsState } from './DogDetailsForm'

type Props = {
  description: string
  planType: 'mix' | 'all' | 'bowl'
  copyContext: string
  namespace: string
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  discountValue: number
  discountBasis: DiscountBasis
  align?: 'center'
}

const Plan = ({
  description,
  planType,
  copyContext,
  namespace,
  preferredLanguage,
  shippingCountryCode,
  discountValue,
  discountBasis,
  align
}: Props): JSX.Element | null => {
  const baseClass = 'rough-cost-calculator__plan'
  const { t } = useTranslation(namespace)

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )

  const dogDetails = useReactiveVar(dogDetailsState)

  const getDiscountedPrice = useCallback(
    (price: number, discountValue: number, discountBasis: DiscountBasis) => {
      if (discountValue && discountBasis === 'percentage') {
        return price - (price * discountValue) / 100
      }
    },
    []
  )

  const dogAgeCategory = ageToAgeCategory(dogDetails.age)

  const dailyPlanPrices = EstimatedDailyPlanPrices.find(
    ({ ageCategory, size }) =>
      ageCategory === dogAgeCategory && size === dogDetails.weight
  )
  if (!dailyPlanPrices) {
    Sentry.captureException(
      'dailyPlanPrices not found for Rough Cost Calculator'
    )
    return null
  }

  const mixedPlanDailyPrice = dailyPlanPrices[shippingCountryCode].mix
  const allPlanDailyPrice = dailyPlanPrices[shippingCountryCode].all

  const allPlanDiscountedPrice =
    (discountValue &&
      discountBasis &&
      getDiscountedPrice(
        allPlanDailyPrice,
        discountValue,
        discountBasis as DiscountBasis
      )) ||
    0

  const mixedPlanDiscountedPrice =
    discountValue && discountBasis
      ? getDiscountedPrice(
          mixedPlanDailyPrice,
          discountValue,
          discountBasis as DiscountBasis
        )
      : 0

  const strikethroughPrice =
    planType === 'mix' ? mixedPlanDailyPrice : allPlanDailyPrice
  const discountedPrice =
    planType === 'mix' ? mixedPlanDiscountedPrice : allPlanDiscountedPrice

  const imageAlt = () => {
    switch (planType) {
      case 'bowl':
        return t(`${copyContext}.bowl_butternut_img_alt`)
      case 'mix':
        return t(`${copyContext}.mix_butternut_img_alt`)
      default:
        return t(`${copyContext}.full_butternut_img_alt`)
    }
  }
  const imageSlug = () => {
    switch (planType) {
      case 'bowl':
        return 'duck-and-chicken-bowl'
      case 'mix':
        return 'most-butternut-with-pink-background'
      default:
        return 'all-butternut-with-pink-background'
    }
  }

  const isDiscounted = !!(discountedPrice && discountedPrice > 0)

  return (
    <div className={`${baseClass} ${align ? `${baseClass}--${align}` : ''}`}>
      <div className={`${baseClass}__image-wrapper`}>
        <Image
          alt={imageAlt()}
          slug={imageSlug()}
          image={{
            width: 80,
            height: 80,
            resizeMode: 'resize_to_fill',
            retina: true
          }}
          className={`${baseClass}__image-wrapper__image`}
        />
      </div>
      <div className={`${baseClass}__text-wrapper`}>
        <p className="text-regular-16 no-scale">{description}</p>
        <p>
          <span
            className={`display-24 no-scale ${
              isDiscounted ? baseClass + '__discounted-price' : ''
            }`}
          >
            {formatCurrencyWithDecimal(
              isDiscounted ? discountedPrice : strikethroughPrice,
              {
                locale,
                currency
              }
            )}
          </span>
          <span className="text-regular-16 no-scale">
            {t(`${copyContext}.per_day`)}
          </span>
        </p>
        {isDiscounted && (
          <p className="text-regular-14 no-scale strikethrough">
            <span>
              {formatCurrencyWithDecimal(strikethroughPrice, {
                locale,
                currency
              })}
            </span>
            {t(`${copyContext}.per_day`)}
          </p>
        )}
      </div>
    </div>
  )
}

export type { Props }
export default Plan
