// @flow

import * as React from 'react'
import { Video as CLVideo } from 'cloudinary-react'

type CropType =
  | 'scale'
  | 'fill'
  | 'fit'
  | 'limit'
  | 'pad'
  | 'lpad'
  | 'crop'

type GravityType =
  | 'auto'
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'west'
  | 'center'
  | 'east'
  | 'south_west'
  | 'south'
  | 'south_east'

type CloudinaryParams = {|
  path: string,
  width?: number,
  height?: number,
  mode?: string,
  quality?: string,
  crop?: CropType,
  gravity?: GravityType,
  format?: string,
  dpr?: number,
  aspectRatio?: string,
  duration?: number,
  x?: number,
  y?: number
|}

type Props = {|
  video: CloudinaryParams,
  alt: string,
  autoPlay?: boolean,
  loop?: boolean,
  muted?: boolean,
  playsInline?: boolean,
  controls?: boolean,
  onPlay?: () => void,
  poster?: ?string,
  className?: string,
  preload?: 'none' | 'auto' | 'metadata'
|}

type Ref = {|
  current: null | HTMLVideoElement
|}

const CloudName = 'ldhg5acsz'
const CloudPath = `https://res.cloudinary.com/${CloudName}/video/upload/`

/**
* A video element that uses the cloudinary API and React package to dynamically
* transform our uploaded images
* Default props, following the pattern of existing videos in the codebase, are:
*     loop, muted, playsInline = true
*     autoplay, controls = false
*
* The onPlay optional prop can be used to add an analytics event.
*
* Cloudinary allows us to set many transformations including height, width,
* mode (scale, crop, etc), and quality (by default auto: good).
* All cloudinary props other than path are optional.
*
* Example usage
* <VideoElement
*   autoPlay
*   loop
*   muted
*   playsInline
*   alt='A dog waiting for a bowl of Butternut'
*   video={{path: 'Web/videos/dogs/dog-with-bowl'}}
* />
*/

const VideoElement = React.forwardRef<Props, HTMLVideoElement>(({
  onPlay,
  video,
  alt,
  autoPlay = false,
  loop = true,
  muted = true,
  playsInline = true,
  controls = false,
  poster = null,
  className,
  preload
}: Props, ref: React.Ref<'video'>): React.Element<'video'> => (
  /*
  * If we set a video height or width to anything less than 0 the
  * Cloudinary default width or height will be set instead.
  */
  <CLVideo
    className={className}
    cloudName='ldhg5acsz'
    publicId={video.path}
    width={typeof video.width === 'number' && video.width > 0 ? video.width : null}
    height={typeof video.height === 'number' && video.height > 0 ? video.height : null}
    mode={video.mode ? video.mode : null}
    quality={video.quality ? video.quality : 'auto:good'}
    crop={video.crop ? video.crop : null}
    gravity={video.gravity ? video.gravity : null}
    autoPlay={autoPlay}
    loop={loop}
    muted={muted}
    playsInline={playsInline}
    controls={controls}
    controlsList="nodownload"
    onPlay={onPlay}
    disablepictureinpicture="true"
    innerRef={ref}
    alt={alt}
    fetchFormat={video.format ? video.format : 'auto'}
    dpr={video.dpr ? video.dpr : null}
    aspectRatio={video.aspectRatio ? video.aspectRatio : null}
    duration={video.duration ? video.duration : null}
    poster={poster}
    preload={preload}
    x={video.x}
    y={video.y}
  />
))

export default VideoElement
export {
  CloudPath
}

export type { Ref }
