// @noflow
import classnames from 'classnames'
import Handlebars from 'handlebars'
import { TOptions } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'react-render-markup'

import STYLES from './Text.module.sass'

type AllowedElements =
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span'
  | 'div'
  | 'li'

// Note: Display variants should only be 'brandBlue500' OR 'brandWhite'
type AllowedColours =
  | 'brandBlue100'
  | 'brandBlue200'
  | 'brandBlue300'
  | 'brandBlue400'
  | 'brandBlue500'
  | 'brandBlue600'
  | 'brandRed300'
  | 'brandRed400'
  | 'brandRed500'
  | 'brandRed600'
  | 'brandYellow100'
  | 'brandYellow300'
  | 'brandYellow600'
  | 'brandYellow700'
  | 'brandPink200'
  | 'brandPink400'
  | 'brandPink500'
  | 'brandWhite'
  | 'supportGreen300'
  | 'supportGreen400'
  | 'supportGreen500'
  | 'supportOrange300'
  | 'supportOrange400'
  | 'supportOrange500'
  | 'dangerRed300'
  | 'dangerRed500'
  | 'supportRed300'
  | 'supportRed500'
  | 'supportBlue300'
  | 'supportBlue500'
  | 'grey400'
  | 'shadowBlack'
  | 'cardboardBrown'
  | 'boostLight'
  | 'boostDark'
  | 'boostLightest'

type AllowedAlignment = 'left' | 'right' | 'center'

type Props = {
  variant?: keyof typeof STYLES
  margin?: boolean
  element?: AllowedElements
  namespace?: string
  text: string | Array<string>
  translate?: boolean
  bold?: boolean
  variables?: TOptions
  colour?: AllowedColours
  align?: AllowedAlignment
  shouldScale?: boolean
  wonky?: boolean
  underline?: boolean
  hideOverflow?: boolean
  uppercase?: boolean
  strikeThrough?: boolean
  tight?: boolean
  dataTestId?: string
}

type InjectedTextProps = {
  children: Array<string>
  type: keyof typeof STYLES
}

const allowedHtmlElement = ['strong', 'em', 'b', 'a', 'br', 'span', 'accent'] // strips all other elements

// This allows us to add specific styling to certain word(s) in a string by
// wrapping custom <accent> wrappers around them and declaring the style 'type'
// with a class within the Text component stylesheet. See Storybook for examples
// under 'With Underline'
const AccentText = ({ children, type }: InjectedTextProps) => {
  return <span className={STYLES[type]}>{children[0]}</span>
}

const replace = {
  accent: AccentText
}

const Text = ({
  variant = 'textRegular16',
  element = 'p',
  namespace = '',
  margin = true,
  text,
  translate = true,
  bold = false,
  variables = {},
  colour = 'brandBlue500',
  align,
  shouldScale = true,
  wonky = false,
  underline = false,
  hideOverflow = false,
  uppercase = false,
  strikeThrough = false,
  tight = false,
  dataTestId
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)
  const Element = element as keyof JSX.IntrinsicElements
  const translatedText = translate
    ? t(text, variables)
    : Handlebars.compile(text)(variables)

  const applyRotation = (text: string) => {
    return text
      .split('')
      .map((char, index) => {
        const rotateAngle = index % 2 === 0 ? '-5' : '5'
        const minWidth = char === ' ' ? 'min-width: 4px' : ''

        return `<span style="transform: rotate(${rotateAngle}deg); display: inline-flex; ${minWidth}">${char}</span>`
      })
      .join('')
  }

  const textToDisplay = wonky ? applyRotation(translatedText) : translatedText

  const className = classnames(STYLES.wrapper, {
    [STYLES[variant]]: variant,
    [STYLES.nomargin]: !margin,
    [STYLES.bold]: bold,
    [STYLES.underline]: underline,
    [STYLES[colour]]: colour,
    [STYLES[`${align}Align` as keyof typeof STYLES]]: align,
    [STYLES.scale]: shouldScale,
    [STYLES.hideOverflow]: hideOverflow,
    [STYLES.uppercase]: uppercase,
    [STYLES.strikeThrough]: strikeThrough,
    [STYLES.tight]: tight
  })

  return (
    <Element data-testid={dataTestId} className={className}>
      <Markup
        allowed={allowedHtmlElement}
        markup={textToDisplay}
        replace={replace}
      />
    </Element>
  )
}

export { Props, AllowedColours, AllowedAlignment }
export default Text
