import React from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'react-render-markup'

type Props = {
  text: string
  namespace: string
}

const WonkyText = ({ text, namespace }: Props): JSX.Element => {
  const allowedHtmlElement = ['strong', 'em', 'b', 'a', 'br', 'span', 'accent'] // strips all other elements

  const { t } = useTranslation(namespace)

  // This function applies rotation to characters in given text while also preventing
  // line breaks from ocurring in the middle of a word.
  // <br /> can't be added to .yml because it will be rotated, rather than rendered - use \n instead
  // when adding new lines in .yml
  const applyRotation = (text: string) => {
    // Split string into words
    const words = t(text).split(' ')

    // Rotate the characters in each word
    const rotatedWords = words.map((word) => {
      const rotatedWord = word
        .split('')
        .map((char, index) => {
          if (char === '\n') return '<br />'

          const rotateAngle =
            index % 2 === 0
              ? 'transform: rotate(-5deg)'
              : 'transform: rotate(5deg)'
          const minWidth = char === ' ' ? 'min-width: 10px' : ''

          return `<span style="${rotateAngle}; display: inline-flex; ${minWidth}">${char}</span>`
        })
        .join('')
      // Prevent line breaks occurring in the middle of a word
      // eslint-disable-next-line i18next/no-literal-string
      return `<span style="white-space: nowrap;">${rotatedWord}</span>`
    })
    // Join words back into one string
    return rotatedWords.join(' ')
  }

  return <Markup allowed={allowedHtmlElement} markup={applyRotation(text)} />
}

export { Props }
export default WonkyText
