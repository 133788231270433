// @noflow
import type { CookiePreferences } from '@/components/cookie_consent/index'
import { Action } from '@/components/shared/CookieConsent/types'
import type { LocalStorageCookieConsent } from '@/components/shared/CookieConsent/types'

const cookieConsentLocalStorageKey = 'cookieConsent'

type Props = LocalStorageCookieConsent & {
  applyPreferences?: (preferences: CookiePreferences) => void
}

const actionToCookieConsent = ({
  action,
  preferences,
  applyPreferences
}: Props): void => {
  switch (action) {
    case Action.Accepted: {
      if (applyPreferences) {
        applyPreferences({ marketing: true, analytics: true })
      }

      localStorage.setItem(
        cookieConsentLocalStorageKey,
        JSON.stringify({
          state: 'accepted',
          preferences: { marketing: true, analytics: true }
        })
      )

      break
    }
    case Action.None: {
      localStorage.setItem(cookieConsentLocalStorageKey, 'null')
      break
    }
    case Action.Cancelled: {
      break
    }
    case Action.Saved: {
      if (applyPreferences && preferences) {
        applyPreferences(preferences)
      }
      localStorage.setItem(
        cookieConsentLocalStorageKey,
        JSON.stringify({
          state: 'accepted',
          preferences
        })
      )
      break
    }
    case Action.Deny: {
      if (applyPreferences) {
        applyPreferences({ marketing: false, analytics: false })
      }
      localStorage.setItem(
        cookieConsentLocalStorageKey,
        JSON.stringify({
          state: 'accepted',
          preferences: { marketing: false, analytics: false }
        })
      )
    }
  }
}

export { actionToCookieConsent }
