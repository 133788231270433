// @noflow
import 'intersection-observer'

import * as Sentry from '@/utils/sentry'

const { IntersectionObserver } = global

const dropIntersectionPixel = (): HTMLElement | undefined => {
  const pixel = document.createElement('div')
  pixel.setAttribute('id', 'cookie-intersection-pixel')
  pixel.style.position = 'absolute'
  pixel.style.marginTop = '2000px'
  pixel.style.top = '0'
  if (!document.body) {
    Sentry.captureException('Document body not found for dropIntersectionPixel')
    return
  }
  document.body.appendChild(pixel)
  return pixel
}

const checkPixelIntersection = (callback: () => void): void => {
  const pixel = dropIntersectionPixel()
  const observer = new IntersectionObserver(
    (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver
    ): void => {
      entries.forEach(function (entry: IntersectionObserverEntry): void {
        if (entry.isIntersecting) {
          callback()
          observer.disconnect()
        }
      })
    }
  )
  if (pixel) {
    observer.observe(pixel)
  }
}

export { checkPixelIntersection }
