// @noflow
import { useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'

import ManageModal, {
  ManageProps
} from '@/components/shared/CookieConsent/Modals/ManageModal'
import StrictModal, {
  StrictProps
} from '@/components/shared/CookieConsent/Modals/StrictModal'

import { Code as CountryCode } from '@/types'

import { cookieConsentModalState } from '../CookieConsent'

enum Variant {
  Strict = 'strict',
  Default = 'default'
}

type BaseProps = ManageProps | StrictProps

type BaseModalProps = BaseProps & {
  shippingCountryCode: CountryCode
  handleModalClose: () => void
  setShowStrictModal: (arg0: boolean) => void
}

type Props = BaseProps & {
  variant: Variant
}

const CookieConsentModal = ({
  variant,
  applyPreferences
}: Props): JSX.Element => {
  const countryCodeCookiesKey = 'user_country_code'
  const cookieConsentModal = useReactiveVar(cookieConsentModalState)
  const shippingCountryCode =
    (Cookies.get(countryCodeCookiesKey) as CountryCode) || CountryCode.GB

  const [isModalOpen, setIsModalOpen] = useState(cookieConsentModal.visible)
  const [showStrictModal, setShowStrictModal] = useState(
    variant === Variant.Strict && cookieConsentModal.visible
  )

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)

    setTimeout(() => {
      cookieConsentModalState({ visible: false })
    }, 300)
  }, [])

  useEffect(() => {
    setIsModalOpen(cookieConsentModal.visible)
    setShowStrictModal(variant === Variant.Strict && cookieConsentModal.visible)
  }, [cookieConsentModal, variant])

  return (
    <>
      <div
        className={`cookie-modal-overlay ${isModalOpen ? 'transition-in' : ''}`}
      />
      <section className={`cookie-modal ${isModalOpen ? 'transition-in' : ''}`}>
        {showStrictModal ? (
          <StrictModal
            shippingCountryCode={shippingCountryCode}
            applyPreferences={applyPreferences}
            handleModalClose={handleModalClose}
            setShowStrictModal={setShowStrictModal}
          />
        ) : (
          <ManageModal
            variant={variant}
            shippingCountryCode={shippingCountryCode}
            applyPreferences={applyPreferences}
            handleModalClose={handleModalClose}
            setShowStrictModal={setShowStrictModal}
          />
        )}
      </section>
    </>
  )
}

export default CookieConsentModal

export { Variant, BaseModalProps }
