import { gql } from '@apollo/client'

const SAVE_LANGUAGE_MUTATION = gql`
  mutation SaveLanguageUpdate($language: Language!) {
    languageUpdate(language: $language) {
      __typename
    }
  }
`

export default SAVE_LANGUAGE_MUTATION
