// @noflow
import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '../../../Image/Image'
import { BreedSelectorVariant } from '../../BreedSelector'

type PopularBreed = {
  key: string
  number: number
  slug: string
  isPopular: true
}

type NonPopularBreed = {
  key: string
  isPopular: false
}

type Props = {
  selectedBreed: NonPopularBreed | PopularBreed
  namespace: string
  isPreWizardGuest: boolean
  variant: BreedSelectorVariant
}

const BreedCard = ({
  selectedBreed,
  namespace,
  isPreWizardGuest,
  variant = BreedSelectorVariant.Generic
}: Props): JSX.Element => {
  const { t } = useTranslation(namespace)

  return (
    <div
      id="matchedBreedCard"
      className={`breed-card__animation-wrapper breed-card__wrapper`}
    >
      <div
        className={`breed-card__card ${
          variant === BreedSelectorVariant.Homepage
            ? 'breed-card__card--homepage'
            : ''
        }`}
      >
        {selectedBreed.isPopular ? (
          <div className="breed-card__matched-breed">
            <div className="breed-card__matched-breed__image">
              <Image
                alt=""
                slug={selectedBreed.slug}
                image={{
                  width: 90,
                  height: 90,
                  resizeMode: 'resize_to_fill'
                }}
              />
            </div>
            <div>
              <div className="breed-card__banner">
                <p
                  className="text-regular-16"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `breed_autocomplete.feedback.popular_breed_html${
                        isPreWizardGuest ? '_pre_wizard_guest' : ''
                      }`,
                      {
                        number: selectedBreed.number,
                        sanitisedNamePlural: t(
                          `models:breeds.${selectedBreed.key}.sanitised_name_plural`
                        )
                      }
                    )
                  }}
                />
              </div>
            </div>
          </div>
        ) : // Not most popular breed
        selectedBreed.key !== 'dalmatian' ? (
          <div className="breed-card__banner">
            <p className="text-regular-16">
              {t(
                selectedBreed.key === 'other'
                  ? `breed_autocomplete.feedback.other_breed${
                      isPreWizardGuest ? '_pre_wizard_guest' : ''
                    }`
                  : `breed_autocomplete.feedback.generic_breed${
                      isPreWizardGuest ? '_pre_wizard_guest' : ''
                    }`
              )}
            </p>
          </div>
        ) : (
          <p
            className="text-regular-16"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(`breed_autocomplete.feedback.dalmatian_atomic`, {
                href: 'https://help.butternutbox.com/en/articles/119-dalmatians-and-butternut%20target=  '
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default BreedCard
export type { PopularBreed, NonPopularBreed }
