const ageOptions = [
  {
    value: 0,
    text: '0_year'
  },
  {
    value: 1,
    text: '1_year'
  },
  {
    value: 2,
    text: '2_year'
  },
  {
    value: 3,
    text: '3_year'
  },
  {
    value: 4,
    text: '4_year'
  },
  {
    value: 5,
    text: '5_year'
  },
  {
    value: 6,
    text: '6_year'
  },
  {
    value: 7,
    text: '7_year'
  },
  {
    value: 8,
    text: '8_year'
  },
  {
    value: 9,
    text: '9_year'
  },
  {
    value: 10,
    text: '10_year'
  },
  {
    value: 11,
    text: '11_year'
  },
  {
    value: 12,
    text: '12_year'
  }
]

export { ageOptions }
