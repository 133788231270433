// @noflow
import type { Language } from '@/packs/localisation'
import type { Locale as DateLocale } from 'date-fns'
import { de, enGB, fr, nl, nlBE, pl } from 'date-fns/locale'

import { lngStringToLngType } from '@/utils/StringHelper'

import type {
  Code as CountryCode,
  Currency,
  CurrencySymbol
} from '@/shared_types/rails_models/shipping_countries'

type Locale =
  | 'en-GB'
  | 'en-NI'
  | 'en-IE'
  | 'en-NL'
  | 'nl-NL'
  | 'nl-GB'
  | 'nl-IE'
  | 'nl-NI'
  | 'nl-BE'
  | 'pl-PL'
  | 'fr-BE'
  | 'de-DE'

type LocaleCurrency = {
  locale: Locale
  currency: Currency
  currencySymbol: CurrencySymbol
}

const countryCodeToDefaultCurrency = (countryCode: CountryCode): Currency => {
  switch (countryCode) {
    case 'GB': {
      return 'GBP'
    }
    case 'NI': {
      return 'GBP'
    }
    case 'IE':
    case 'NL':
    case 'BE':
    case 'DE': {
      return 'EUR'
    }
    case 'PL': {
      return 'PLN'
    }
    default: {
      throw new Error(
        `countryCodeToDefaultCurrency is not implemented for this countryCode, countryCode is: ${countryCode}`
      )
    }
  }
}

const countryCodeToDefaultCurrencySymbol = (
  countryCode: CountryCode
): CurrencySymbol => {
  switch (countryCode) {
    case 'GB': {
      return '£'
    }
    case 'NI': {
      return '£'
    }
    case 'IE':
    case 'NL':
    case 'BE':
    case 'DE': {
      return '€'
    }
    case 'PL': {
      return 'zł'
    }
    default: {
      throw new Error(
        `countryCodeToDefaultCurrencySymbol is not implemented for this countryCode, countryCode is: ${countryCode}`
      )
    }
  }
}

const countryCodeToLocale = (
  countryCode: CountryCode,
  language: Language
): Locale => {
  const sanitisedLanguageCode = lngStringToLngType(language)
  switch (sanitisedLanguageCode) {
    case 'nl_BE':
      return 'nl-BE'
    case 'pl_PL':
      return 'pl-PL'
    case 'de_DE':
      return 'de-DE'
    default:
      return `${language}-${countryCode}` as Locale
  }
}

const countryCodeToLocaleCurrency = (
  shippingCountryCode: CountryCode,
  language: Language
): LocaleCurrency => {
  const defaultCurrency = countryCodeToDefaultCurrency(shippingCountryCode)
  const defaultCurrencySymbol =
    countryCodeToDefaultCurrencySymbol(shippingCountryCode)

  const countryCode = shippingCountryCode

  if (!language)
    throw new Error(
      `countryCodeToLocaleCurrency is not implemented for this language, language is: ${language}`
    )

  const locale = countryCodeToLocale(countryCode, language)

  return {
    currency: defaultCurrency,
    currencySymbol: defaultCurrencySymbol,
    locale
  }
}

const languageToSanitisedLocale = (
  shippingCountryCode: CountryCode,
  language: Language
): string => {
  switch (language) {
    case 'nl_BE':
      return 'nl-BE'
    case 'pl_PL':
      return 'pl-PL'
    case 'de_DE':
      return 'de-DE'
    default:
      return `${language}-${shippingCountryCode}`
  }
}

const localeToDateLocale = (
  shippingCountryCode: CountryCode,
  language: Language
): DateLocale => {
  const sanitisedLanguageCode = lngStringToLngType(language)
  const locale = languageToSanitisedLocale(
    shippingCountryCode,
    sanitisedLanguageCode
  )
  switch (locale) {
    case 'en-GB':
    case 'en-NI':
    case 'en-IE':
    case 'en-NL':
      return enGB
    case 'nl-NL':
    case 'nl-GB':
    case 'nl-IE':
    case 'nl-NI':
      return nl
    case 'nl-BE':
      return nlBE
    case 'pl-PL':
      return pl
    case 'fr':
    case 'fr-BE':
      return fr
    case 'de':
    case 'de-DE':
      return de
    default:
      return enGB
  }
}

const countryCodeToPrivacyUrl = (countryCode: CountryCode): string => {
  switch (countryCode) {
    case 'GB':
    case 'NI':
    case 'IE':
      return '/privacy-policy-gb'
    case 'NL':
      return '/privacy-policy-nl'
    case 'BE':
      return '/privacy-policy-be'
    case 'PL':
      return '/privacy-policy-pl'
    case 'DE':
      return '/privacy-policy-de'
    default:
      return '/privacy-policy-gb'
  }
}

const countrySpecificInstagramLink = (countryCode: CountryCode): string => {
  switch (countryCode) {
    case 'NL': {
      return 'https://www.instagram.com/butternutbox.nl/'
    }
    case 'BE': {
      return 'https://www.instagram.com/butternutbox.be/'
    }
    case 'PL': {
      return 'https://www.instagram.com/psibufet/'
    }
    case 'DE': {
      return 'https://www.instagram.com/butternutbox.de/'
    }
    case 'GB':
    case 'IE':
    case 'NI': {
      return 'https://www.instagram.com/butternutbox'
    }
    default: {
      throw new Error(
        `countrySpecificInstagramLink is not implemented for ${countryCode}`
      )
    }
  }
}

const countrySpecificFacebookLink = (countryCode: CountryCode): string => {
  switch (countryCode) {
    case 'IE': {
      return 'https://www.facebook.com/ButternutBox.ie/'
    }
    case 'NL': {
      return 'https://www.facebook.com/ButternutBox.nl/'
    }
    case 'BE': {
      return 'https://www.facebook.com/ButternutBox.be/'
    }
    case 'PL': {
      return 'https://www.facebook.com/psibufet/'
    }
    case 'DE': {
      return 'https://www.facebook.com/profile.php?id=61556277572237'
    }
    case 'GB':
    case 'NI': {
      return 'https://www.facebook.com/ButternutBox/'
    }
    default: {
      throw new Error(
        `countrySpecificFacebookLink is not implemented for ${countryCode}`
      )
    }
  }
}

const countrySpecificTikTokLink = (countryCode: CountryCode): string => {
  switch (countryCode) {
    case 'PL': {
      return 'https://www.tiktok.com/@psibufet'
    }
    default: {
      return 'https://www.tiktok.com/@butternutbox'
    }
  }
}

export type { Locale, LocaleCurrency }
export {
  countryCodeToLocaleCurrency,
  countryCodeToDefaultCurrencySymbol,
  countryCodeToDefaultCurrency,
  localeToDateLocale,
  countryCodeToPrivacyUrl,
  countrySpecificInstagramLink,
  countrySpecificFacebookLink,
  countrySpecificTikTokLink,
  countryCodeToLocale
}
