// @noflow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToDefaultCurrencySymbol } from '@/utils/countryCodeHelper'
import { isDevelopment } from '@/utils/isDevelopment'

import BlueArrow from 'assets/images/icons/arrows/arrow-blue.svg'

import type {
  Code as CountryCode,
  ShippingCountry
} from '@/shared_types/rails_models/shipping_countries'

import { getCountryFlag, getCountryName } from './localisationHelpers'

const baseClass = 'region-widget'

const CountryOption = ({
  countryCode,
  countryOptionsAreVisible,
  setCountryOptionsAreVisible,
  activeShippingCountries,
  setTemporaryShippingCountry,
  domain
}: {
  countryCode: CountryCode
  countryOptionsAreVisible: boolean
  setCountryOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>
  activeShippingCountries: Array<ShippingCountry>
  setTemporaryShippingCountry: React.Dispatch<React.SetStateAction<CountryCode>>
  domain: string
}): React.ReactElement<'div'> => {
  const { t } = useTranslation(['shipping_countries', 'currency'])
  const setCountryLocation = React.useCallback(() => {
    setTemporaryShippingCountry(countryCode)
    setCountryOptionsAreVisible(!countryOptionsAreVisible)

    const currentDomain = window.location.hostname

    if (!isDevelopment() && currentDomain !== domain) {
      // eslint-disable-next-line i18next/no-literal-string
      window.location.href = `https://${domain}`
    }
  }, [
    countryCode,
    setTemporaryShippingCountry,
    countryOptionsAreVisible,
    setCountryOptionsAreVisible,
    domain
  ])

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key !== 'Tab') {
        setCountryLocation()
      }
    },
    [setCountryLocation]
  )

  return (
    <div
      id={countryCode}
      data-testid={`country-option-${countryCode}`}
      className={`${baseClass}__country-option`}
      onClick={setCountryLocation}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <img
        src={getCountryFlag(countryCode) || ''}
        className={`${baseClass}__country-flag`}
        alt={`The flag of ${getCountryName({
          activeShippingCountries,
          countryCode,
          t
        })}`}
        height="25"
        width="43"
      />
      <span className={`${baseClass}__country-name text-regular-18`}>
        {getCountryName({ activeShippingCountries, countryCode, t }) || ''}
      </span>
      <span className={`${baseClass}__selected-currency text-regular-18`}>
        {countryCodeToDefaultCurrencySymbol(countryCode)}
      </span>
    </div>
  )
}

const RegionWidget = ({
  activeShippingCountries,
  setTemporaryShippingCountry,
  temporaryShippingCountry,
  setCountryOptionsAreVisible,
  countryOptionsAreVisible
}: {
  activeShippingCountries: Array<ShippingCountry>
  setTemporaryShippingCountry: React.Dispatch<React.SetStateAction<CountryCode>>
  temporaryShippingCountry: CountryCode
  setCountryOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>
  countryOptionsAreVisible: boolean
}): React.ReactElement<'div'> => {
  const { t } = useTranslation('shipping_countries')
  const countryOptions = activeShippingCountries.filter(
    (country: ShippingCountry) => country.code !== temporaryShippingCountry
  )

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key !== 'Tab') {
        setCountryOptionsAreVisible(!countryOptionsAreVisible)
      }
    },
    [setCountryOptionsAreVisible, countryOptionsAreVisible]
  )

  const handleClick = React.useCallback(() => {
    setCountryOptionsAreVisible(!countryOptionsAreVisible)
  }, [setCountryOptionsAreVisible, countryOptionsAreVisible])

  return (
    <div className={baseClass}>
      <div
        data-testid="region-widget"
        className={`${baseClass}__selected-country text-regular-18`}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <img
          src={getCountryFlag(temporaryShippingCountry) || ''}
          className={`${baseClass}__country-flag`}
          alt={`The flag of ${getCountryName({
            activeShippingCountries,
            countryCode: temporaryShippingCountry,
            t
          })}`}
          height="25"
          width="43"
        />
        <span
          className={`${baseClass}__selected-country__name text-regular-18`}
        >
          {getCountryName({
            activeShippingCountries,
            countryCode: temporaryShippingCountry,
            t
          }) || ''}
        </span>
        <span className={`${baseClass}__selected-currency text-regular-18`}>
          {countryCodeToDefaultCurrencySymbol(temporaryShippingCountry)}
        </span>
        <div className={`${baseClass}__selected-country__arrow-wrapper`}>
          <img
            src={BlueArrow}
            className={`${baseClass}__selected-country__arrow${
              countryOptionsAreVisible ? '--up' : '--down'
            }`}
            alt={`A blue arrow pointing ${
              countryOptionsAreVisible ? 'up' : 'down'
            }`}
            height="16"
            width="19"
          />
        </div>
      </div>
      {countryOptionsAreVisible && (
        <div className={`${baseClass}__country-options`}>
          {countryOptions.map(
            ({ code, domain }: ShippingCountry): React.ReactElement => (
              <CountryOption
                key={code}
                countryCode={code}
                countryOptionsAreVisible={countryOptionsAreVisible}
                setCountryOptionsAreVisible={setCountryOptionsAreVisible}
                activeShippingCountries={activeShippingCountries}
                setTemporaryShippingCountry={setTemporaryShippingCountry}
                domain={domain}
              />
            )
          )}
        </div>
      )}
    </div>
  )
}

export default RegionWidget
