// @noflow
import type { Language as LanguageType } from '@/packs/localisation'
import { useMutation, useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import React from 'react'
import { useTranslation } from 'react-i18next'

import segmentTrack from '@/components/analytics/Analytics'
import withApollo from '@/components/apollo/withApollo'
import TransitionalModal from '@/components/shared/TransitionalModal'
import LanguageWidget from '@/components/shared/geo_ip_widget/LanguageWidget'
import RegionWidget from '@/components/shared/geo_ip_widget/RegionWidget'

import SAVE_LANGUAGE_MUTATION from './queries/saveLanguage'
import USER_TYPE_QUERY from './queries/userQuery'

import type {
  SaveLanguageUpdate,
  SaveLanguageUpdateVariables
} from './queries/__generated__/SaveLanguageUpdate'
import type { UserQuery } from './queries/__generated__/UserQuery'
import type {
  Code as CountryCode,
  ShippingCountry
} from '@/shared_types/rails_models/shipping_countries'
import { Language } from '@/types'

import { getLanguageName } from '../localisationHelpers'

const languageCookiesKey = 'user_language'
const countryCodeCookiesKey = 'user_country_code'

type AnalyticsProps = {
  widgetLocation: string
  currentLanguage?: LanguageType
  newLanguage?: LanguageType
  currentCountryCode?: CountryCode
  newCountryCode?: CountryCode
}

const triggerLocalisationWidgetAnalytics = ({
  widgetLocation,
  currentLanguage,
  newLanguage,
  currentCountryCode,
  newCountryCode
}: {
  widgetLocation: string
  currentLanguage?: LanguageType
  newLanguage?: LanguageType
  currentCountryCode?: CountryCode
  newCountryCode?: CountryCode
}): void => {
  const eventName = 'Localisation Preferences Updated'
  const properties: AnalyticsProps = {
    widgetLocation
  }
  if (currentLanguage) {
    properties.currentLanguage = currentLanguage
    properties.newLanguage = newLanguage
  }
  if (currentCountryCode) {
    properties.currentCountryCode = currentCountryCode
    properties.newCountryCode = newCountryCode
  }
  segmentTrack(eventName, properties)
}

const saveLanguageAndCountry = ({
  newCountryCode,
  currentCountryCode,
  newLanguage,
  currentLanguage,
  widgetLocation
}: {
  widgetLocation: string
  currentLanguage: LanguageType
  newLanguage: LanguageType
  currentCountryCode: CountryCode
  newCountryCode: CountryCode
}): void => {
  let languageUpdated = false
  let countryUpdated = false
  const cookieOptions =
    window.location.hostname === 'localhost'
      ? undefined
      : { domain: window.location.hostname }
  if (newLanguage !== currentLanguage) {
    Cookies.set(languageCookiesKey, newLanguage, cookieOptions)
    languageUpdated = true
  }
  if (newCountryCode !== currentCountryCode) {
    Cookies.set(countryCodeCookiesKey, newCountryCode, cookieOptions)
    countryUpdated = true
  }
  if (languageUpdated || countryUpdated) {
    if (languageUpdated && countryUpdated) {
      triggerLocalisationWidgetAnalytics({
        currentLanguage,
        newLanguage,
        currentCountryCode,
        newCountryCode,
        widgetLocation
      })
    } else if (languageUpdated) {
      triggerLocalisationWidgetAnalytics({
        currentLanguage,
        newLanguage,
        widgetLocation
      })
    } else if (countryUpdated) {
      triggerLocalisationWidgetAnalytics({
        currentCountryCode,
        newCountryCode,
        widgetLocation
      })
    }
    window.location.reload()
  }
}

const LocalisationModal = ({
  currentCountryCode,
  currentLanguage,
  activeShippingCountries,
  closeModal,
  isOpen,
  widgetLocation
}: {
  currentCountryCode: CountryCode
  currentLanguage: LanguageType
  activeShippingCountries: Array<ShippingCountry>
  closeModal: () => void
  isOpen: boolean
  widgetLocation: string
}): React.ReactElement | null => {
  const [temporaryLanguage, setTemporaryLanguage] =
    React.useState(currentLanguage)
  const [temporaryShippingCountry, setTemporaryShippingCountry] =
    React.useState(currentCountryCode)
  const [countryOptionsAreVisible, setCountryOptionsAreVisible] =
    React.useState(false)
  const [languageOptionsAreVisible, setLanguageOptionsAreVisible] =
    React.useState(false)
  const { loading, data, error } = useQuery<UserQuery>(USER_TYPE_QUERY)
  const [saveLanguage] = useMutation<
    SaveLanguageUpdate,
    SaveLanguageUpdateVariables
  >(SAVE_LANGUAGE_MUTATION, {
    onCompleted() {
      window.location.reload()
    }
  })

  const saveLanguagePostWizard = React.useCallback(
    ({
      newLanguage,
      currentLanguage,
      widgetLocation
    }: {
      newLanguage: LanguageType
      currentLanguage: LanguageType
      widgetLocation: string
    }) => {
      if (newLanguage !== currentLanguage) {
        const cookieOptions =
          window.location.hostname === 'localhost'
            ? undefined
            : { domain: window.location.hostname }
        Cookies.set(languageCookiesKey, newLanguage, cookieOptions)

        triggerLocalisationWidgetAnalytics({
          currentLanguage,
          newLanguage,
          widgetLocation
        })

        switch (newLanguage) {
          case 'pl_PL': {
            return saveLanguage({
              variables: {
                language: Language.pl_PL
              }
            })
          }
          case 'pl': {
            return saveLanguage({
              variables: {
                language: Language.pl
              }
            })
          }
          case 'nl_BE': {
            return saveLanguage({
              variables: {
                language: Language.nl_BE
              }
            })
          }
          case 'nl': {
            return saveLanguage({
              variables: {
                language: Language.nl
              }
            })
          }
          case 'en': {
            return saveLanguage({
              variables: {
                language: Language.en
              }
            })
          }
          case 'de_DE': {
            return saveLanguage({
              variables: {
                language: Language.de_DE
              }
            })
          }
        }
      }
    },
    [saveLanguage]
  )

  const closeDropdowns = React.useCallback((e) => {
    // Clicking anywhere in the modal other than the two widgets will close the dropdowns
    if (
      !e.target.classList.contains('region-widget__selected-country') &&
      !e.target.classList.contains('language-widget__selected-language')
    ) {
      setCountryOptionsAreVisible(false)
      setLanguageOptionsAreVisible(false)
    }
  }, [])

  const sanitisePolishLanguage = React.useCallback(
    (temporaryShippingCountry, currentCountryCode, language: LanguageType) => {
      if (temporaryShippingCountry === 'PL') {
        switch (language) {
          case 'pl_PL':
          case 'pl':
            return Language.pl_PL
          default:
            return language
        }
      } else if (
        temporaryShippingCountry !== 'PL' &&
        currentCountryCode === 'PL'
      ) {
        switch (language) {
          case 'pl_PL':
          case 'pl':
            return Language.pl
          default:
            return language
        }
      } else {
        return language
      }
    },
    []
  )

  const handleSavePreWizard = React.useCallback((): void => {
    const newLanguage = sanitisePolishLanguage(
      temporaryShippingCountry,
      currentCountryCode,
      temporaryLanguage
    )
    saveLanguageAndCountry({
      currentLanguage,
      currentCountryCode,
      newLanguage,
      newCountryCode: temporaryShippingCountry,
      widgetLocation
    })
  }, [
    sanitisePolishLanguage,
    temporaryShippingCountry,
    currentCountryCode,
    temporaryLanguage,
    currentLanguage,
    widgetLocation
  ])

  const handleSavePostWizard = React.useCallback((): void => {
    const newLanguage = sanitisePolishLanguage(
      temporaryShippingCountry,
      currentCountryCode,
      temporaryLanguage
    )
    saveLanguagePostWizard({ currentLanguage, newLanguage, widgetLocation })
  }, [
    sanitisePolishLanguage,
    temporaryShippingCountry,
    currentCountryCode,
    temporaryLanguage,
    saveLanguagePostWizard,
    currentLanguage,
    widgetLocation
  ])

  const shouldShowLanguageDropdown = (): boolean => {
    const languages = activeShippingCountries.map(
      (country: ShippingCountry) => {
        return country.defaultLanguage
      }
    )
    const languageOptions = [...new Set(languages)]
    return languageOptions.length > 1
  }

  const { t } = useTranslation('localisation_modal')

  if (loading || !data) return null

  if (error)
    throw new Error(`Error when GUEST_QUERY executes: ${error.message}`)

  const isPreWizardGuest = (): boolean => {
    return data.currentUser?.__typename === 'PreWizardGuest'
  }

  const languages = activeShippingCountries
    .filter((country) => temporaryShippingCountry === country.code)
    .flatMap((country) => country.availableLanguages)

  return (
    <TransitionalModal
      closeModal={closeModal}
      extraClasses="localisation-modal"
      modalIsOpen={isOpen}
      modalSize="large"
      showCloseButton
      transitionTypes={{ desktop: 'scale', mobile: 'bottom' }}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={closeDropdowns}
        className="localisation-modal__content"
        onClick={closeDropdowns}
      >
        <h2 className="display-24">{t('title')}</h2>
        {isPreWizardGuest() && (
          <React.Fragment>
            <h3
              className="widget-title text-regular-16"
              key="region-widget-title"
            >
              {t('widget_region_title')}
            </h3>
            <RegionWidget
              key="region-widget"
              setTemporaryShippingCountry={setTemporaryShippingCountry}
              temporaryShippingCountry={temporaryShippingCountry}
              activeShippingCountries={activeShippingCountries}
              setCountryOptionsAreVisible={setCountryOptionsAreVisible}
              countryOptionsAreVisible={countryOptionsAreVisible}
            />
          </React.Fragment>
        )}
        <h3 className="widget-title text-regular-16">
          {t('widget_language_title')}
        </h3>
        {shouldShowLanguageDropdown() ? (
          <LanguageWidget
            setTemporaryLanguage={setTemporaryLanguage}
            temporaryLanguage={temporaryLanguage}
            setLanguageOptionsAreVisible={setLanguageOptionsAreVisible}
            languageOptionsAreVisible={languageOptionsAreVisible}
            availableLanguages={languages}
          />
        ) : (
          <p className="localisation-modal__current-language text-regular-18">
            {getLanguageName({ language: temporaryLanguage, t }) || ''}
          </p>
        )}
        <div className="localisation-modal__btn-group">
          <button
            type="button"
            className="btn secondary cancel"
            onClick={closeModal}
          >
            {t('cancel')}
          </button>
          <button
            type="button"
            className="btn primary save"
            onClick={
              isPreWizardGuest() ? handleSavePreWizard : handleSavePostWizard
            }
          >
            {t('save')}
          </button>
        </div>
      </div>
    </TransitionalModal>
  )
}

export default withApollo(LocalisationModal)
