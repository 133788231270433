// @noflow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { captureException } from '@/utils/sentry'

import InjectedMarkupText from '../../InjectedMarkupText'
import type { AmbassadorReferralLinkProps } from '../RafModal'

type Props = {
  namespace: string
  closeRafModal: () => void
  referrerName: AmbassadorReferralLinkProps['referrerName']
  profession: AmbassadorReferralLinkProps['profession']
  companyName: AmbassadorReferralLinkProps['companyName']
  refereeDiscountMessage: AmbassadorReferralLinkProps['refereeDiscountMessage']
}

const AmbassadorReferralLinkContent = ({
  namespace,
  closeRafModal,
  referrerName,
  profession,
  companyName,
  refereeDiscountMessage
}: Props): React.ReactElement => {
  const { t } = useTranslation(namespace)
  const copyContext = 'raf_modal'

  // Turns 'john doe' => 'John Doe'
  const capitalise = (chars: string) =>
    chars
      .trim()
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ')

  const referrerNameProfession: Array<Props['profession']> = [
    'trainer',
    'behaviourist',
    'groomer',
    'vet',
    'vet_nurse',
    'nutritionist',
    'breeder',
    'dog_walker',
    'dog_therapist'
  ]

  const companyNameProfession: Array<Props['profession']> = [
    'doggy_day_care',
    'charity',
    'rescue_centre',
    'pet_accessories',
    'restaurant',
    'dog_show',
    'dog_blog',
    'other'
  ]

  const generateTitle = () => {
    if (referrerNameProfession.some((name) => name === profession)) {
      return t(`${copyContext}.ambassadors.title_profession`, {
        referrerName: capitalise(referrerName),
        context: profession
      })
    }

    if (companyNameProfession.some((name) => name === profession)) {
      return t(`${copyContext}.ambassadors.title_company_name`, {
        companyName: capitalise(companyName)
      })
    }

    captureException(`Unhandled AmbassadorReferralLinkContent profession`, {
      extra: { profession: profession }
    })
    return t(`${copyContext}.ambassadors.title_generic`)
  }

  return (
    <div className="raf-modal__content__copy">
      <div className="raf-modal__content__heading">
        <h2 className="display-28">{generateTitle()}</h2>
      </div>
      <div className="raf-modal__content__discount">
        <p className="text-regular-16">
          {<InjectedMarkupText text={refereeDiscountMessage} />}
        </p>
        <button className="btn primary" onClick={closeRafModal} type="button">
          {t(`${copyContext}.button_text`)}
        </button>
        <p className="text-regular-12">
          <InjectedMarkupText
            text={t(`${copyContext}.ambassadors.join_other_referred_customers`)}
          />
        </p>
      </div>
    </div>
  )
}

export default AmbassadorReferralLinkContent
