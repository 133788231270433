// @noflow
import type { Language } from '@/packs/localisation'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import BlueArrow from 'assets/images/icons/arrows/arrow-blue.svg'

import { getLanguageName } from './localisationHelpers'

type Props = {
  setTemporaryLanguage: React.Dispatch<React.SetStateAction<Language>>
  temporaryLanguage: Language
  languageOptionsAreVisible: boolean
  setLanguageOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>
  availableLanguages: Array<Language>
}

type LanguageOptionsProps = {
  language: Language
  languageOptionsAreVisible: boolean
  setLanguageOptionsAreVisible: React.Dispatch<React.SetStateAction<boolean>>
  setTemporaryLanguage: React.Dispatch<React.SetStateAction<Language>>
  disabled: boolean
}
const baseClass = 'language-widget'

const LanguageOption = ({
  language,
  languageOptionsAreVisible,
  setLanguageOptionsAreVisible,
  setTemporaryLanguage,
  disabled
}: LanguageOptionsProps): React.ReactElement<'div'> => {
  const { t } = useTranslation(['shipping_countries', 'currency'])

  const setNewLanguage = React.useCallback(() => {
    setLanguageOptionsAreVisible(!languageOptionsAreVisible)
    setTemporaryLanguage(language)
  }, [
    setLanguageOptionsAreVisible,
    languageOptionsAreVisible,
    setTemporaryLanguage,
    language
  ])

  return (
    <button
      id={language}
      className={`${baseClass}__language-option`}
      onClick={setNewLanguage}
      type="button"
      disabled={disabled}
    >
      <span className={`${baseClass}__language-name text-regular-18`}>
        {getLanguageName({ language, t }) || ''}
      </span>
    </button>
  )
}

const LanguageWidget = ({
  setTemporaryLanguage,
  temporaryLanguage,
  languageOptionsAreVisible,
  setLanguageOptionsAreVisible,
  availableLanguages
}: Props): React.ReactElement<'div'> => {
  const { t } = useTranslation('shipping_countries')

  const languageOptions = availableLanguages.filter(
    (language) => language !== temporaryLanguage
  )

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key !== 'Tab') {
        setLanguageOptionsAreVisible(!languageOptionsAreVisible)
      }
    },
    [setLanguageOptionsAreVisible, languageOptionsAreVisible]
  )

  const handleClick = React.useCallback(() => {
    setLanguageOptionsAreVisible(!languageOptionsAreVisible)
  }, [setLanguageOptionsAreVisible, languageOptionsAreVisible])

  return (
    <div className={baseClass}>
      <div
        className={`${baseClass}__selected-language text-regular-18`}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <span
          className={`${baseClass}__selected-language__name text-regular-18`}
        >
          {getLanguageName({ language: temporaryLanguage, t }) || ''}
        </span>
        <div className={`${baseClass}__selected-language__arrow-wrapper`}>
          <img
            src={BlueArrow}
            className={`${baseClass}__selected-language__arrow${
              languageOptionsAreVisible ? '--up' : '--down'
            }`}
            alt={`A blue arrow pointing ${
              languageOptionsAreVisible ? 'up' : 'down'
            }`}
            height="16"
            width="19"
          />
        </div>
      </div>
      {languageOptionsAreVisible && (
        <div className={`${baseClass}__language-options`}>
          {languageOptions
            .sort((languageA, languageB) => {
              const languageAName = getLanguageName({ language: languageA, t })
              const languageBName = getLanguageName({ language: languageB, t })

              return languageAName && languageBName
                ? languageAName.localeCompare(languageBName)
                : 0
            })
            .map((language) => (
              <LanguageOption
                key={language}
                language={language}
                languageOptionsAreVisible={languageOptionsAreVisible}
                setLanguageOptionsAreVisible={setLanguageOptionsAreVisible}
                setTemporaryLanguage={setTemporaryLanguage}
                disabled={false}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default LanguageWidget
