// @noflow
import type {
  Error as GraphQLError,
  GraphQLErrorInstance
} from '@/redux/graphql_queries/errorTypes'

import type { ShippingCountry } from '@/shared_types/rails_models/shipping_countries'
import type { CSRFToken } from '@/shared_types/tokens'

type DecodedGraphQLResponse = {
  data?: {
    shippingCountries: Array<ShippingCountry>
  }
  errors?: Array<GraphQLErrorInstance>
}

type Success = {
  type: 'Success'
  data: Array<ShippingCountry>
}

type RequestResult = Success | GraphQLError

const fetchSupportedShippingCountriesRequest = async ({
  csrfToken
}: {
  csrfToken: CSRFToken
}): Promise<RequestResult> => {
  const endpoint = '/graphql'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const method = 'POST'
  const credentials = 'same-origin'
  // eslint-disable-next-line i18next/no-literal-string
  const graphQLQuery = `
      query SupportedShippingCountriesData {
        shippingCountries {
          id,
          name,
          code,
          currency,
          active,
          scaEnforced,
          defaultLanguage,
          domain,
          availableLanguages
        }
      }
    `
  const body = JSON.stringify({
    query: graphQLQuery
  })

  const response = await fetch(endpoint, { headers, method, credentials, body })
  if (!response.ok) {
    return response.text().then((message: string): RequestResult => {
      return {
        type: 'ServerError',
        status: response.status,
        message
      }
    })
  }
  return response
    .json()
    .then(({ data, errors }: DecodedGraphQLResponse): RequestResult => {
      if (data) {
        const { shippingCountries } = data
        const activeShippingCountries = shippingCountries.filter(
          (country: ShippingCountry) => country.active === true
        )

        return {
          type: 'Success',
          data: activeShippingCountries
        }
      } else if (errors) {
        return {
          type: 'GraphQLError',
          errors
        }
      } else {
        return {
          type: 'UnknownError',
          error: 'Unknown Error'
        }
      }
    })
}

const fetchActiveShippingCountries = async ({
  csrfToken
}: {
  csrfToken: CSRFToken
}): Promise<Array<ShippingCountry>> => {
  const supportedShippingCountries =
    await fetchSupportedShippingCountriesRequest({ csrfToken }).then(
      (requestResult: RequestResult) => {
        switch (requestResult.type) {
          case 'ServerError': {
            throw new Error(
              `Server Error: status=${requestResult.status}, message=${requestResult.message}`
            )
          }
          case 'GraphQLError': {
            throw new Error(
              requestResult.errors
                .map(({ message }: { message: string }): string => message)
                .join(', ')
            )
          }
          case 'UnknownError': {
            throw new Error(`Unknown Error: ${requestResult.error}`)
          }
          case 'Success': {
            return requestResult.data
          }
        }
      }
    )
  return supportedShippingCountries
}

export default fetchActiveShippingCountries
