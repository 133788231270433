// @noflow
import { makeVar, useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import AgeSelect from './AgeSelect'
import BreedSelect from './BreedSelect'
import WeightSelect from './WeightSelect'

type Props = {
  breedSelectRef?: React.RefObject<HTMLDivElement>
  onBreedFocusCallback?: () => void
  fullWidth?: boolean
}

type DogDetails = {
  age: number
  weight: string | number
  interactedWithAge: boolean
}

// Retrieve any saved dog details from localStorage if user has interacted before
const roughCostCalculatorLocalStorage = localStorage.getItem(
  'rough_cost_calculator'
)

const initialState = {
  age: 2,
  weight: 'medium_2',
  interactedWithAge: false
}

const parsedState = roughCostCalculatorLocalStorage
  ? JSON.parse(roughCostCalculatorLocalStorage)
  : initialState

const dogDetailsState = makeVar<DogDetails>(parsedState)

const baseClass = 'rough-cost-calculator__cost-calculator'

const DogDetailsForm = ({
  breedSelectRef,
  onBreedFocusCallback,
  fullWidth = false
}: Props): JSX.Element => {
  const dogDetails = useReactiveVar(dogDetailsState)

  useEffect(() => {
    localStorage.setItem('rough_cost_calculator', JSON.stringify(dogDetails))
  }, [dogDetails])

  return (
    <>
      <BreedSelect
        breedSelectRef={breedSelectRef}
        onFocusCallback={onBreedFocusCallback}
      />
      {fullWidth ? (
        <>
          <div className={`${baseClass}__dropdown-row`}>
            <AgeSelect />
          </div>
          <div className={`${baseClass}__dropdown-row`}>
            <WeightSelect />
          </div>
        </>
      ) : (
        <div className={`${baseClass}__dropdown-row`}>
          <AgeSelect />
          <WeightSelect />
        </div>
      )}
    </>
  )
}
export default DogDetailsForm

export { dogDetailsState }
