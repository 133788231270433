/**
 * Plays videos when they become visible in the viewport and pauses them when they are out of view.
 */
const playVideosOnScroll = (): void => {
  const videos = document.querySelectorAll('video')

  videos.forEach((video) => {
    video.load()
    video.muted = true

    const playPromise = video.play()

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                video.play()
              } else {
                video.pause()
              }
            })
          })
          observer.observe(video)
        })
        .catch(() => {
          video.pause()
        })
    }
  })
}

export { playVideosOnScroll }
