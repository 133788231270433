// @noflow
import type { Language } from '@/packs/localisation'
import Cookies from 'js-cookie'

import * as Sentry from '@/utils/sentry'

import BelgiumFlag from 'assets/images/icons/flags/be-flag.svg'
import GermanyFlag from 'assets/images/icons/flags/de-flag.svg'
import GreatBritainFlag from 'assets/images/icons/flags/gb-flag.svg'
import IrelandFlag from 'assets/images/icons/flags/ie-flag.svg'
import NetherlandsFlag from 'assets/images/icons/flags/nl-flag.svg'
import PolandFlag from 'assets/images/icons/flags/pl-flag.svg'

import type {
  Code as CountryCode,
  ShippingCountry
} from '@/shared_types/rails_models/shipping_countries'

const countryCodeCookiesKey = 'user_country_code'
const languageCookiesKey = 'user_language'

const getCountryCode = (): CountryCode => {
  try {
    const countryCode = Cookies.get(countryCodeCookiesKey) as CountryCode | null
    if (countryCode) {
      return countryCode
    } else return 'GB'
  } catch (_) {
    return 'GB'
  }
}

const getLanguage = (
  countryCode: CountryCode,
  activeShippingCountries: Array<ShippingCountry>
): Language => {
  try {
    const language = Cookies.get(languageCookiesKey) as Language | null
    if (language) {
      return language
    } else {
      const country = activeShippingCountries.find(
        (country: ShippingCountry): boolean => country.code === countryCode
      )
      if (!country) {
        Sentry.captureException(
          `Could not find countryCode: ${countryCode} in activeShippingCountries`
        )
        return 'en'
      }
      return country.defaultLanguage
    }
  } catch (_) {
    return 'en'
  }
}

const getCountryFlag = (countryCode: CountryCode): string | void => {
  switch (countryCode) {
    case 'GB': {
      return GreatBritainFlag
    }
    case 'IE': {
      return IrelandFlag
    }
    case 'NI': {
      return GreatBritainFlag
    }
    case 'NL': {
      return NetherlandsFlag
    }
    case 'BE': {
      return BelgiumFlag
    }
    case 'PL': {
      return PolandFlag
    }
    case 'DE': {
      return GermanyFlag
    }
    default: {
      return Sentry.captureException(
        `getCountryFlag is not implemented for ${countryCode}`
      )
    }
  }
}

const getCountryName = ({
  activeShippingCountries,
  countryCode,
  t
}: {
  activeShippingCountries: Array<ShippingCountry>
  countryCode: CountryCode
  t: (arg0: string) => string
}): string | void => {
  const country = activeShippingCountries.find(
    (country: ShippingCountry): boolean => country.code === countryCode
  )

  if (!country) {
    return Sentry.captureException(`Country ${country} not found`)
  }
  return t(`shipping_countries:${country.code}.presentable_name`)
}

const getLanguageShortName = ({
  language,
  t
}: {
  language: Language
  t: (arg0: string) => string
}): string | void => {
  if (!language) {
    return Sentry.captureException(`Language ${language} not found`)
  }
  return t(`languages:${language}.short_name`)
}

const getLanguageName = ({
  language,
  t
}: {
  language: Language
  t: (arg0: string) => string
}): string | void => {
  if (!language) {
    return Sentry.captureException(`Language ${language} not found`)
  }
  return t(`languages:${language}.presentable_name`)
}

export {
  getCountryFlag,
  getLanguageName,
  getLanguageShortName,
  getCountryCode,
  getLanguage,
  getCountryName
}
