// @noflow
import BREAKPOINTS from '@/components/theme/breakpoints.sass'

declare type Breakpoints = {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}

export default ((): Breakpoints =>
  Object.fromEntries(
    Object.entries(BREAKPOINTS).map(([key, value]) => [
      key,
      parseInt(value.replace('px', ''))
    ])
  ) as Breakpoints)()
