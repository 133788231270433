// @flow

/**
 * Brand Colours
 * Here is where we define our core brand colours in JavaScript variables so we
 * can pass them as parameters into our asset functions that are stored inside
 * 'javascript/assets/...'. These colours should ALWAYS be in-line with the
 * variables stored in our `_colours.scss` CSS sheet.
 */

type BrandColours = {|
  // Blue
  brandBlue100: '#ecf4f9',
  brandBlue200: '#c9dbe8',
  brandBlue300: '#94b8d1',
  brandBlue400: '#3f769e',
  brandBlue500: '#174f78',
  brandBlue600: '#0f3f61',
  brandBlue700: '#092d46',

  // Yellow
  brandYellow100: '#fbf8e9',
  brandYellow200: '#fbedb6',
  brandYellow300: '#ffe180',
  brandYellow400: '#ffd54b',
  brandYellow500: '#efbf0b',
  brandYellow600: '#d9b226',
  brandYellow700: '#8c741f',

  // Pink
  brandPink100: '#f9ecec',
  brandPink200: '#f2c4c4',
  brandPink300: '#e89797',
  brandPink400: '#df6d6d',
  brandPink500: '#bf4040',
  brandPink600: '#933',
  brandPink700: '#7a1f1f',

  // Red
  brandRed100: '#f9eeec',
  brandRed200: '#f5c5bc',
  brandRed300: '#ef9f90',
  brandRed400: '#e66c55',
  brandRed500: '#cc4b33',
  brandRed600: '#a83924',
  brandRed700: '#7e2b1b',

  // Black
  brandBlack: '#000',

  // White
  brandWhite: '#fff',

  // Brown
  cardboardBrown: '#85614d'
|}

const BRAND_COLOURS: BrandColours = {
  // Blue
  brandBlue100: '#ecf4f9',
  brandBlue200: '#c9dbe8',
  brandBlue300: '#94b8d1',
  brandBlue400: '#3f769e',
  brandBlue500: '#174f78',
  brandBlue600: '#0f3f61',
  brandBlue700: '#092d46',

  // Yellow
  brandYellow100: '#fbf8e9',
  brandYellow200: '#fbedb6',
  brandYellow300: '#ffe180',
  brandYellow400: '#ffd54b',
  brandYellow500: '#efbf0b',
  brandYellow600: '#d9b226',
  brandYellow700: '#8c741f',

  // Pink
  brandPink100: '#f9ecec',
  brandPink200: '#f2c4c4',
  brandPink300: '#e89797',
  brandPink400: '#df6d6d',
  brandPink500: '#bf4040',
  brandPink600: '#933',
  brandPink700: '#7a1f1f',

  // Red
  brandRed100: '#f9eeec',
  brandRed200: '#f5c5bc',
  brandRed300: '#ef9f90',
  brandRed400: '#e66c55',
  brandRed500: '#cc4b33',
  brandRed600: '#a83924',
  brandRed700: '#7e2b1b',

  // Black
  brandBlack: '#000',

  // White
  brandWhite: '#fff',

  // Brown
  cardboardBrown: '#85614d'
}

export default BRAND_COLOURS

export type { BrandColours }
